import { call, put, takeEvery } from "redux-saga/effects"
//  Redux States
import { GET_CLIENT_DETAIL, GET_LASTEST_PROJECT_UNIT } from "./actionTypes"
import {
  getClientDetailSuccess,
  getClientDetailFail,
  getLatestProjectUnitSuccess,
  getLatestProjectUnitFail,
} from "./actions"

import { getClientDetail, getLatestProjectUnit } from "helpers/backend"

function* fetchClientDetail({ clientId }) {
  try {
    const response = yield call(getClientDetail, clientId)
    yield put(getClientDetailSuccess(response))
  } catch (error) {
    yield put(getClientDetailFail(error))
  }
}
function* fetchLatestProjectUnit({ clientId }) {
  try {
    const response = yield call(getLatestProjectUnit, clientId)
    yield put(getLatestProjectUnitSuccess(response))
  } catch (error) {
    yield put(getLatestProjectUnitFail(error))
  }
}

function* customerProfilePreviewSaga() {
  yield takeEvery(GET_CLIENT_DETAIL, fetchClientDetail)
  yield takeEvery(GET_LASTEST_PROJECT_UNIT, fetchLatestProjectUnit)
}

export default customerProfilePreviewSaga
