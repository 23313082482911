import {
  GET_SERVICE_REQUESTS,
  GET_SERVICE_REQUESTS_FAIL,
  GET_SERVICE_REQUESTS_SUCCESS,
  GET_SERVICE_REQUEST_DETAIL,
  GET_SERVICE_REQUEST_DETAIL_FAIL,
  GET_SERVICE_REQUEST_DETAIL_SUCCESS,
  ADD_NEW_SERVICE_REQUEST,
  ADD_SERVICE_REQUEST_SUCCESS,
  ADD_SERVICE_REQUEST_FAIL,
  UPDATE_SERVICE_REQUEST,
  UPDATE_SERVICE_REQUEST_SUCCESS,
  UPDATE_SERVICE_REQUEST_FAIL,
  DELETE_SERVICE_REQUEST,
  DELETE_SERVICE_REQUEST_SUCCESS,
  DELETE_SERVICE_REQUEST_FAIL,
  GET_PROJECT_UNIT_CATEGORY,
  GET_PROJECT_UNIT_CATEGORY_SUCCESS,
  GET_PROJECT_UNIT_CATEGORY_FAIL,
  GET_MAINTENANCE_ISSUES,
  GET_MAINTENANCE_ISSUES_SUCCESS,
  GET_MAINTENANCE_ISSUES_FAIL,
  GET_CLIENT_INVOICES,
  GET_CLIENT_INVOICES_SUCCESS,
  GET_CLIENT_INVOICES_FAIL,
  GET_SUPPORT_TICKET_SUCCESS,
  GET_SUPPORT_TICKET_FAIL,
  GET_SUPPORT_TICKET,
  GET_SERVICE_REQUEST_TYPE,
  GET_SERVICE_REQUEST_TYPE_SUCCESS,
  GET_SERVICE_REQUEST_TYPE_FAIL,
  REMOVE_PREVIOUS_RESPONSE,
  GET_PENDING_REQUESTS,
  GET_PENDING_REQUESTS_SUCCESS,
  GET_PENDING_REQUESTS_FAIL,
  GET_ALL_PROJECTS,
  GET_ALL_PROJECTS_SUCCESS,
  GET_ALL_PROJECTS_FAIL,
  GET_PROJECT_UNIT,
  GET_PROJECT_UNIT_SUCCESS,
  GET_PROJECT_UNIT_FAIL,
  GET_CLIENT_PROJECT_FOR_SERVICE_REQUEST,
  GET_CLIENT_PROJECT_FOR_SERVICE_REQUEST_SUCCESS,
  GET_CLIENT_PROJECT_FOR_SERVICE_REQUEST_FAIL,
} from "./actionTypes"

export const getServiceRequests = (value) => ({
  type: GET_SERVICE_REQUESTS,
  value,
})

export const getServiceRequestsSuccess = serviceRequests => ({
  type: GET_SERVICE_REQUESTS_SUCCESS,
  payload: serviceRequests,
})

export const getServiceRequestsFail = error => ({
  type: GET_SERVICE_REQUESTS_FAIL,
  payload: error,
})
//type of service request
export const getServiceRequestType = () => ({
  type: GET_SERVICE_REQUEST_TYPE,
})

export const getServiceRequestTypeSuccess = serviceRequestType => ({
  type: GET_SERVICE_REQUEST_TYPE_SUCCESS,
  payload: serviceRequestType,
})

export const getServiceRequestTypeFail = error => ({
  type: GET_SERVICE_REQUEST_TYPE_FAIL,
  payload: error,
})

export const addNewServiceRequest = (clientId, serviceRequest, history) => ({
  type: ADD_NEW_SERVICE_REQUEST,
  payload: { clientId, serviceRequest, history },
})

export const addServiceRequestSuccess = serviceRequest => ({
  type: ADD_SERVICE_REQUEST_SUCCESS,
  payload: serviceRequest,
})

export const addServiceRequestFail = error => ({
  type: ADD_SERVICE_REQUEST_FAIL,
  payload: error,
})

export const updateServiceRequest = (id, serviceRequest) => ({
  type: UPDATE_SERVICE_REQUEST,
  payload: { id, serviceRequest },
})

export const updateServiceRequestSuccess = serviceRequest => ({
  type: UPDATE_SERVICE_REQUEST_SUCCESS,
  payload: serviceRequest,
})

export const updateServiceRequestFail = error => ({
  type: UPDATE_SERVICE_REQUEST_FAIL,
  payload: error,
})

export const deleteServiceRequest = serviceRequest => ({
  type: DELETE_SERVICE_REQUEST,
  serviceRequest,
})

export const deleteServiceRequestSuccess = serviceRequest => ({
  type: DELETE_SERVICE_REQUEST_SUCCESS,
  payload: serviceRequest,
})

export const deleteServiceRequestFail = error => ({
  type: DELETE_SERVICE_REQUEST_FAIL,
  payload: error,
})

export const getServiceRequestDetail = serviceRequestId => ({
  type: GET_SERVICE_REQUEST_DETAIL,
  serviceRequestId,
})

export const getServiceRequestDetailSuccess = serviceRequestDetails => ({
  type: GET_SERVICE_REQUEST_DETAIL_SUCCESS,
  payload: serviceRequestDetails,
})

export const getServiceRequestDetailFail = error => ({
  type: GET_SERVICE_REQUEST_DETAIL_FAIL,
  payload: error,
})

////DEPENDNCY
//PROJECT UNIT CATEGORY
export const getProjectUnitCategory = id => ({
  type: GET_PROJECT_UNIT_CATEGORY,
  id,
})

export const getProjectUnitCategorySuccess = projectUnits => ({
  type: GET_PROJECT_UNIT_CATEGORY_SUCCESS,
  payload: projectUnits,
})

export const getProjectUnitCategoryFail = error => ({
  type: GET_PROJECT_UNIT_CATEGORY_FAIL,
  payload: error,
})

//Maintenance issues
export const getMaintenanceIssues = () => ({
  type: GET_MAINTENANCE_ISSUES,
})

export const getMaintenanceIssuesSuccess = maintenanceIssues => ({
  type: GET_MAINTENANCE_ISSUES_SUCCESS,
  payload: maintenanceIssues,
})

export const getMaintenanceIssuesFail = error => ({
  type: GET_MAINTENANCE_ISSUES_FAIL,
  payload: error,
})

//CLIENT INVOICES
export const getClientInvoices = id => ({
  type: GET_CLIENT_INVOICES,
  id,
})

export const getClientInvoicesSuccess = clientInvoices => ({
  type: GET_CLIENT_INVOICES_SUCCESS,
  payload: clientInvoices,
})

export const getClientInvoicesFail = error => ({
  type: GET_CLIENT_INVOICES_FAIL,
  payload: error,
})

//CLIENT INVOICES
export const getSupportTickets = id => ({
  type: GET_SUPPORT_TICKET,
  id,
})

export const getSupportTicketsSuccess = supportTickets => ({
  type: GET_SUPPORT_TICKET_SUCCESS,
  payload: supportTickets,
})

export const getSupportTicketsFail = error => ({
  type: GET_SUPPORT_TICKET_FAIL,
  payload: error,
})

// REMOVE PREVIOUS STATE
export const removePreviousResponse = () => ({ type: REMOVE_PREVIOUS_RESPONSE })

export const getPendingRequests = () => ({
  type: GET_PENDING_REQUESTS,
})

export const getPendingRequestsSuccess = serviceRequests => ({
  type: GET_PENDING_REQUESTS_SUCCESS,
  payload: serviceRequests,
})

export const getPendingRequestsFail = error => ({
  type: GET_PENDING_REQUESTS_FAIL,
  payload: error,
})

//CLIENT INVOICES
export const getAllProjects = id => ({
  type: GET_ALL_PROJECTS,
  id,
})

export const getAllProjectsSuccess = supportTickets => ({
  type: GET_ALL_PROJECTS_SUCCESS,
  payload: supportTickets,
})

export const getAllProjectsFail = error => ({
  type: GET_ALL_PROJECTS_FAIL,
  payload: error,
})

// GET CLIENT PROJECT UNIT

export const getClientProjectUnit = values => ({
  type: GET_PROJECT_UNIT,
  values,
})

export const getClientProjectUnitSuccess = projectUnit => ({
  type: GET_PROJECT_UNIT_SUCCESS,
  payload: projectUnit,
})

export const getClientProjectUnitFail = error => ({
  type: GET_PROJECT_UNIT_FAIL,
  payload: error,
})

// GET CLIENT PROJECTS

export const getClientProject = id => ({
  type: GET_CLIENT_PROJECT_FOR_SERVICE_REQUEST,
  id,
})

export const getClientProjectSuccess = projects => ({
  type: GET_CLIENT_PROJECT_FOR_SERVICE_REQUEST_SUCCESS,
  payload: projects,
})

export const getClientProjectFail = error => ({
  type: GET_CLIENT_PROJECT_FOR_SERVICE_REQUEST_FAIL,
  payload: error,
})
