//Pending Bills

export const GET_PENDING_BILL_LIST = "GET_PENDING_BILL_LIST"
export const GET_PENDING_BILL_LIST_SUCCESS = "GET_PENDING_BILL_LIST_SUCCESS"
export const GET_PENDING_BILL_LIST_FAIL = "GET_PENDING_BILL_LIST_FAIL"

//Bill History

export const GET_BILL_HISTORY_LIST = "GET_BILL_HISTORY_LIST"
export const GET_BILL_HISTORY_LIST_SUCCESS = "GET_BILL_HISTORY_LIST_SUCCESS"
export const GET_BILL_HISTORY_LIST_FAIL = "GET_BILL_HISTORY_LIST_FAIL"

//Bill Receipt
export const GET_BILL_RECEIPT_LIST = "GET_BILL_RECEIPT_LIST"
export const GET_BILL_RECEIPT_LIST_SUCCESS = "GET_BILL_RECEIPT_LIST_SUCCESS"
export const GET_BILL_RECEIPT_LIST_FAIL = "GET_BILL_RECEIPT_LIST_FAIL"

//Bill Receipt
export const GET_RECEIPT_PREVIEW = "GET_RECEIPT_PREVIEW"
export const GET_RECEIPT_PREVIEW_SUCCESS = "GET_RECEIPT_PREVIEW_SUCCESS"
export const GET_RECEIPT_PREVIEW_FAIL = "GET_RECEIPT_PREVIEW_FAIL"

//Bill Credit Note
export const GET_CREDIT_NOTE_LIST = "GET_CREDIT_NOTE_LIST"
export const GET_CREDIT_NOTE_LIST_SUCCESS = "GET_CREDIT_NOTE_LIST_SUCCESS"
export const GET_CREDIT_NOTE_LIST_FAIL = "GET_CREDIT_NOTE_LIST_FAIL"
