/** Get Dashboard Chart data */
export const API_SUCCESS = "API_SUCCESS";
export const API_FAIL = "API_FAIL";
export const GET_CHARTS_DATA = "GET_CHARTS_DATA";

export const GET_LATEST_INVOICE = "GET_LATEST_INVOICE";
export const GET_LATEST_INVOICE_SUCCESS = "GET_LATEST_INVOICE_SUCCESS";
export const GET_LATEST_INVOICE_FAIL = "GET_LATEST_INVOICE_FAIL";

export const GET_INVOICE_DATA = "GET_INVOICE_DATA";
export const GET_INVOICE_DATA_SUCCESS = "GET_INVOICE_DATA_SUCCESS";
export const GET_INVOICE_DATA_FAIL = "GET_INVOICE_DATA_FAIL";

export const GET_PREVIOUS_INVOICES_LIST = "GET_PREVIOUS_INVOICES_LIST";
export const GET_PREVIOUS_INVOICES_LIST_SUCCESS = "GET_PREVIOUS_INVOICES_LIST_SUCCESS";
export const GET_PREVIOUS_INVOICES_LIST_FAIL = "GET_PREVIOUS_INVOICES_LIST_FAIL";

export const GET_SERVICE_REQUEST="GET_SERVICE_REQUEST"
export const GET_SERVICE_REQUEST_SUCCESS="GET_SERVICE_REQUEST_SUCCESS"
export const GET_SERVICE_REQUEST_FAIL="GET_SERVICE_REQUEST_FAIL"

//graph for consumption api
export const GET_WATER_CONSUMPTION="GET_WATER_CONSUMPTION"
export const GET_WATER_CONSUMPTION_SUCCESS="GET_WATER_CONSUMPTION_SUCCESS"
export const GET_WATER_CONSUMPTION_FAIL="GET_WATER_CONSUMPTION_FAIL"

export const GET_ELECTRICITY_CONSUMPTION="GET_ELECTRICITY_CONSUMPTION"
export const GET_ELECTRICITY_CONSUMPTION_SUCCESS="GET_ELECTRICITY_CONSUMPTION_SUCCESS"
export const GET_ELECTRICITY_CONSUMPTION_FAIL="GET_ELECTRICITY_CONSUMPTION_FAIL"

export const GET_GAS_CONSUMPTION="GET_GAS_CONSUMPTION"
export const GET_GAS_CONSUMPTION_SUCCESS="GET_GAS_CONSUMPTION_SUCCESS"
export const GET_GAS_CONSUMPTION_FAIL="GET_GAS_CONSUMPTION_FAIL"

export const GET_DG_CONSUMPTION="GET_DG_CONSUMPTION"
export const GET_DG_CONSUMPTION_SUCCESS="GET_DG_CONSUMPTION_SUCCESS"
export const GET_DG_CONSUMPTION_FAIL="GET_DG_CONSUMPTION_FAIL"