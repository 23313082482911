
//COMPANY FILTER

export const GET_COMPANY_FILTER = "GET_COMPANY_FILTER"
export const GET_COMPANY_FILTER_SUCCESS = "GET_COMPANY_FILTER_SUCCESS"
export const GET_COMPANY_FILTER_FAIL = "GET_COMPANY_FILTER_FAIL"

//COMPANY LOCATION FILTER

export const GET_COMPANY_LOCATION_FILTER = "GET_COMPANY_LOCATION_FILTER"
export const GET_COMPANY_LOCATION_FILTER_SUCCESS = "GET_COMPANY_LOCATION_FILTER_SUCCESS"
export const GET_COMPANY_LOCATION_FILTER_FAIL = "GET_COMPANY_LOCATION_FILTER_FAIL"

//CUSTOMER FILTER

export const GET_CUSTOMER_FILTER = "GET_CUSTOMER_FILTER"
export const GET_CUSTOMER_FILTER_SUCCESS = "GET_CUSTOMER_FILTER_SUCCESS"
export const GET_CUSTOMER_FILTER_FAIL = "GET_CUSTOMER_FILTER_FAIL"

//PROJECT FILTER

export const GET_PROJECT_FILTER="GET_PROJECT_FILTER"
export const GET_PROJECT_FILTER_SUCCESS="GET_PROJECT_FILTER_SUCCESS"
export const GET_PROJECT_FILTER_FAIL="GET_PROJECT_FILTER_FAIL"

//FINANCIAL YEAR FILTER

export const GET_FINANCIAL_YEAR_FILTER="GET_FINANCIAL_YEAR_FILTER"
export const GET_FINANCIAL_YEAR_FILTER_SUCCESS="GET_FINANCIAL_YEAR_FILTER_SUCCESS"
export const GET_FINANCIAL_YEAR_FILTER_FAIL="GET_FINANCIAL_YEAR_FILTER_FAIL"