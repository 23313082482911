import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import TableContainer from "./creditHistoryListContainer"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap"

// import { Email, Tags, Projects } from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"
import {
  getBillHistoryList,
  getCreditNoteList,
} from "store/bills&payment/actions"
import handleDownloadInvoice from "components/Common/handleDownloadInvoice"
import LargeModal from "VIP PAGES/Dashboard/viewInvoiceModal"

const CreditHistoryList = () => {
  const navigate = useNavigate()
  document.title = "Credit Note History"
  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const [clientId, setClientId] = useState(authUser ? authUser.id : "")
  const dispatch = useDispatch()
  const billHistoryListState = state =>
    state.billAndPayments.creditNoteList.data
  const loadingState = state => state.billAndPayments.loading
  const billHistory = useSelector(billHistoryListState) || []
  const loading = useSelector(loadingState) || []

  const [isLoading, setLoading] = useState(loading)

  useEffect(() => {
    const value = { client_id: clientId, user_type: authUser.user_type }
    dispatch(getCreditNoteList(value))
  }, [dispatch])

  const [modalLarge, setModalLarge] = useState(false)

  const toggleModalLarge = () => setModalLarge(!modalLarge)
  const [billingId, setBillingId] = useState()

  const handleBillPreview = id => {
    setBillingId(id)
    toggleModalLarge()
  }

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "srNo",
        sortBy: true,
        // filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {/* <Link className="text-dark" to="#">#00001</Link> */}
                <Link className="text-dark" to="#">
                  {" "}
                  {cellProps.row.index + 1}
                </Link>
              </h5>
            </>
          )
        },
      },

      {
        Header: (
          <div style={{ whiteSpace: "nowrap" }}>
            <span style={{ display: "block" }}>Credit </span>
            <span style={{ display: "block" }}>Note No</span>
          </div>
        ),
        accessor: "credit_note_no",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link
                  className="text-dark"
                  // to={`/service-request/view-service/${cellProps.row.original.id}`}
                >
                  {cellProps.row.original.credit_note_no}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: (
          <div style={{ whiteSpace: "nowrap" }}>
            <span style={{ display: "block" }}>Credit </span>
            <span style={{ display: "block" }}>Note Date</span>
          </div>
        ),
        accessor: "credit_note_date",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.credit_note_date}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: (
          <div style={{ whiteSpace: "nowrap" }}>
            <span style={{ display: "block" }}>Original</span>
            <span style={{ display: "block" }}> Invoice no</span>
          </div>
        ),
        accessor: "bill_no",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.bill_no}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: (
          <div style={{ whiteSpace: "nowrap" }}>
            <span style={{ display: "block" }}>Original </span>
            <span style={{ display: "block" }}>Inv Date</span>
          </div>
        ),
        accessor: "bill_date",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.bill_date}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: (
          <div style={{ whiteSpace: "nowrap" }}>
            <span style={{ display: "block" }}>Billing</span>
            <span style={{ display: "block" }}>Company</span>
          </div>
        ),
        accessor: "company_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.company_name}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: (
          <div style={{ whiteSpace: "nowrap" }}>
            <span style={{ display: "block" }}>Project</span>
            <span style={{ display: "block" }}>Name</span>
          </div>
        ),
        accessor: "project_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.project_name}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: (
          <div style={{ whiteSpace: "nowrap" }}>
            <span style={{ display: "block" }}>Customer</span>
            <span style={{ display: "block" }}>Name</span>
          </div>
        ),
        accessor: "customer_name",

        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.customer_name}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: (
          <div style={{ whiteSpace: "nowrap" }}>
            <span style={{ display: "block" }}>Credit </span>
            <span style={{ display: "block" }}>Amount</span>
          </div>
        ),
        accessor: "all_total",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.all_total}
                </Link>
              </h5>
            </>
          )
        },
      },

      {
        Header: (
          <div style={{ whiteSpace: "nowrap" }}>
            <span style={{ display: "block" }}>Financial</span>
            <span style={{ display: "block" }}>Year</span>
          </div>
        ),
        accessor: "financial_year",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.financial_year}
                </Link>
              </h5>
            </>
          )
        },
      },
      // {
      //   Header: "Action",
      //   Cell: cellProps => {
      //     return (
      //       <div className="d-flex gap-3">
      //         <a
      //           // href={cellProps.row.original.download_document}
      //           // target="_blank"
      //           // rel="noopener noreferrer"
      //           // className="text-success"
      //           // download
      //           onClick={() => {
      //             handleDownloadInvoice(cellProps.row.original.billing_id)
      //           }}
      //         >
      //           <i
      //             className="mdi mdi-download font-size-18"
      //             id={`downloadleaseagreementtooltip`}
      //             style={{ color: "#3366cc" }}
      //           />
      //           <UncontrolledTooltip
      //             placement="top"
      //             target={`downloadleaseagreementtooltip`}
      //           >
      //             Download
      //           </UncontrolledTooltip>
      //         </a>
      //         <a

      //           onClick={()=>{handleBillPreview(cellProps.row.original.billing_id)}}
      //         >
      //           <i
      //             className="mdi mdi-eye font-size-18"
      //             id={`viewleaseagreementtooltip-1`}
      //             style={{ color: "#3366cc" }}
      //           />
      //           <UncontrolledTooltip
      //             placement="top"
      //             target={`viewleaseagreementtooltip-1`}
      //           >
      //             View
      //           </UncontrolledTooltip>
      //         </a>
      //       </div>
      //     )
      //   },
      // },
    ],
    []
  )

  const handleUserClick = arg => {
    const user = arg
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = users => {
    setDeleteId({ id: users })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (deleteId && deleteId.id) {
      dispatch(deletePendingBills(deleteId.id))
    }
    onPaginationPageChange(1)
    setDeleteModal(false)
    navigate("/pendingBills")
    dispatch(getPendingBillsList())
  }

  const handleUserClicks = () => {
    setUserList("")
    toggle()
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <LargeModal
        isOpen={modalLarge}
        toggle={toggleModalLarge}
        billingId={billingId}
      />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Bills & Payment"
            breadcrumbItem="Credit Note History"
          />

          <Row>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      isPagination={true}
                      columns={columns}
                      data={billHistory || []}
                      isGlobalFilter={true}
                      isShowingPageLength={true}
                      isAddUserList={true}
                      iscustomPageSizeOptions={true}
                      handleUserClick={handleUserClicks}
                      customPageSize={10}
                      tableClass="table align-middle table-nowrap table-hover"
                      theadClass="table-light"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination pagination-rounded justify-content-end mt-4"
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CreditHistoryList
