import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Modal,
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
  Row,
  Table,
} from "reactstrap"
import { Link, NavLink } from "react-router-dom"
import SimpleBar from "simplebar-react"
// import logo from "../../assets/images/VipClients/V1Fullnobg.png"
import logo from "../../assets/images/VipClients/V1MallCropped.svg"

import LargeModal from "./projectUnitDetailsModal"
import { useDispatch } from "react-redux"
import { getClientDetail, getLatestProjectUnit } from "store/actions"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"

const customerProfilePreview = () => {
  const [modal_large, setmodal_large] = useState(false)
  const [modalLarge, setModalLarge] = useState(false)

  const toggleModalLarge = () => setModalLarge(!modalLarge)

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_large() {
    setmodal_large(!modal_large)
    removeBodyCss()
  }
  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const [clientId, setClientId] = useState(authUser ? authUser.id : "")

  const dispatch = useDispatch()
  const clientDetailState = state =>
    state.customerProfilePreview.clientDetail.client
  const projectUnitState = state =>
    state.customerProfilePreview.latestProjectUnit.project_units
  const loadingState = state => state.customerProfilePreview.loading
  const customerProfilePreviewProperties = createSelector(
    clientDetailState,
    projectUnitState,
    loadingState,
    (clientDetail, projectUnit, loading) => ({
      clientDetail: clientDetail,
      projectUnit: projectUnit,
      loading: loading,
    })
  )

  const { clientDetail, projectUnit, loading } =
    useSelector(customerProfilePreviewProperties) || []
  const [isLoading, setLoading] = useState(loading)

  useEffect(() => {

    dispatch(getClientDetail({client_id :clientId,user_type:authUser.user_type}))
    dispatch(getLatestProjectUnit({client_id :clientId,user_type:authUser.user_type}))
  }, [dispatch])

  return (
    <React.Fragment>
      <LargeModal isOpen={modalLarge} toggle={toggleModalLarge} />

      <header>
        <div className="navbar-header">
          <div className="d-flex">
            <span className="logo-sm ms-5">
              <img src={logo} alt="" height="35" />
            </span>
          </div>
          <div className="d-flex">
            <Link to={"/change-password"}>
              <Button
                className="me-3"
                style={{
                  backgroundColor: "#E6E6E6",
                  color: "black",
                  border: "none",
                  fontSize: "10px",
                  fontWeight: "500",
                }}
              >
                Change Password
              </Button>
            </Link>
            <Link to={"/dashboard"}>
              <Button
                className="me-5"
                style={{
                  backgroundColor: "#3882E8",
                  color: "white",
                  border: "none",
                  fontSize: "10px",
                  fontWeight: "500",
                }}
              >
                Proceed to Dashboard
              </Button>
            </Link>
          </div>
        </div>
      </header>
      <div className="page-content" style={{ backgroundColor: "#E6E6E6" }}>
        {" "}
        <Container fluid={true}>
          <div className="row justify-content-center">
            <div className="col-md-5">
              <Card className="overflow-hidden">
                <div style={{ backgroundColor: "#F6F6F6" }}>
                  <Row className="mt-5">
                    <Col>
                      <div className="p-3">
                        <h5>
                          <strong>Welcome</strong>
                        </h5>
                        <p>to our Clienteles portal</p>
                      </div>
                    </Col>
                    <Col xs="5" className="align-self-end">
                      <img src="" alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <Row className="mt-">
                    <Col sm="4">
                      <div className="avatar-lg profile-user-wid mb-4">
                        <img
                          src={clientDetail && clientDetail.image}
                          alt="ALT"
                          className="img-thumbnail rounded-circle"
                          style={{ height: "90px"}}
                        />
                      </div>
                    </Col>
                    <Col sm="8">
                      <div className="pt-4">
                        <Row>
                          <Col xs="6">
                            <h5 className="font-size-15">
                              {clientDetail && clientDetail.customer_name}
                            </h5>
                            <p className="text-muted mb-0">
                              {clientDetail && clientDetail.email_address}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle className="mb-5">Project Unit</CardTitle>

                  {/* Table for project details */}
                  <table className="table mb-4">
                    <tbody>
                      <tr>
                        <td>Project Name</td>
                        <td>{projectUnit && projectUnit.project_name}</td>
                      </tr>
                      <tr>
                        <td>Unit</td>
                        <td>{projectUnit && projectUnit.unit_code}</td>
                      </tr>
                      <tr>
                        <td>Project Start Date</td>
                        <td>
                          {projectUnit && projectUnit.agreement_start_date}
                        </td>
                      </tr>
                      <tr>
                        <td>Project End Date</td>
                        <td>{projectUnit && projectUnit.agreement_end_date}</td>
                      </tr>
                    </tbody>
                  </table>

                  {/* Button */}
                  <div className="text-center mt-4">
                    <Button
                      type="button"
                      onClick={toggleModalLarge}
                      className="btn btn-light"
                      data-toggle="modal"
                      data-target=".bs-example-modal-lg"
                      style={{
                        backgroundColor: "#3882E8",
                        color: "white",
                        border: "none",
                        fontSize: "10px",
                        fontWeight: "500",
                      }}
                    >
                      Project Unit Details
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-md-5">
              <SimpleBar style={{ maxHeight: "700px" }}>
                <div className="table-responsive">
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-5">Profile Details</CardTitle>
                      <ul type="none">
                        {
                          <li>
                            <div className="flex-shrink-0 d-flex">
                              <div className="me-3">
                                <h5 className="font-size-14">Shop Type:</h5>
                              </div>
                              <div className="flex-grow-1">
                                <div>
                                  {clientDetail &&
                                  clientDetail.customer_type === 1
                                    ? "Individual"
                                    : "Brand"}
                                </div>
                              </div>
                            </div>
                          </li>
                        }
                        <hr></hr>
                        {
                          <li>
                            <div className="flex-shrink-0 d-flex">
                              <div className="me-3">
                                <h5 className="font-size-14">Shop Name:</h5>
                              </div>
                              <div className="flex-grow-1">
                                <div>
                                  {" "}
                                  {clientDetail && clientDetail.customer_name}
                                </div>
                              </div>
                            </div>
                          </li>
                        }
                        <hr></hr>
                        {
                          <li>
                            <div className="flex-shrink-0 d-flex">
                              <div className="me-3">
                                <h5 className="font-size-14">Shop Code:</h5>
                              </div>
                              <div className="flex-grow-1">
                                <div>
                                  {clientDetail && clientDetail.customer_code}
                                </div>
                              </div>
                            </div>
                          </li>
                        }
                      </ul>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-5">Contact Details</CardTitle>
                      <ul type="none">
                        {
                          <li>
                            <div className="flex-shrink-0 d-flex">
                              <div className="me-3">
                                <h5 className="font-size-14">Email Address</h5>
                              </div>
                              <div className="flex-grow-1">
                                <div>
                                  {clientDetail && clientDetail.email_address}
                                </div>
                              </div>
                            </div>
                          </li>
                        }
                        <hr></hr>
                        {
                          <li>
                            <div className="flex-shrink-0 d-flex">
                              <div className="me-3">
                                <h5 className="font-size-14">Phone No.</h5>
                              </div>
                              <div className="flex-grow-1">
                                <div>
                                  {clientDetail && clientDetail.mobile_no}
                                </div>
                              </div>
                            </div>
                          </li>
                        }
                        <hr></hr>
                        {
                          <li>
                            <div className="flex-shrink-0 d-flex">
                              <div className="me-3">
                                <h5 className="font-size-14">Address</h5>
                              </div>
                              <div className="flex-grow-1">
                                <div>
                                  {clientDetail && clientDetail.address}
                                </div>
                              </div>
                            </div>
                          </li>
                        }
                        <hr></hr>

                        {
                          <li>
                            <div className="flex-shrink-0 d-flex">
                              <div className="me-3">
                                <h5 className="font-size-14">Pincode</h5>
                              </div>
                              <div className="flex-grow-1">
                                <div>
                                  {clientDetail && clientDetail.pincode}
                                </div>
                              </div>
                            </div>
                          </li>
                        }
                        <hr></hr>
                        {
                          <li>
                            <div className="flex-shrink-0 d-flex">
                              <div className="me-3">
                                <h5 className="font-size-14">City</h5>
                              </div>
                              <div className="flex-grow-1">
                                <div>
                                  {clientDetail && clientDetail.city_name}
                                </div>
                              </div>
                            </div>
                          </li>
                        }
                        <hr></hr>
                        {
                          <li>
                            <div className="flex-shrink-0 d-flex">
                              <div className="me-3">
                                <h5 className="font-size-14">State</h5>
                              </div>
                              <div className="flex-grow-1">
                                <div>
                                  {clientDetail && clientDetail.state_name}
                                </div>
                              </div>
                            </div>
                          </li>
                        }
                        <hr></hr>
                        {
                          <li>
                            <div className="flex-shrink-0 d-flex">
                              <div className="me-3">
                                <h5 className="font-size-14">Country</h5>
                              </div>
                              <div className="flex-grow-1">
                                <div>
                                  {clientDetail && clientDetail.country_name}
                                </div>
                              </div>
                            </div>
                          </li>
                        }
                      </ul>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-5">
                        Document Identities
                      </CardTitle>
                      <ul type="none">
                        {
                          <li>
                            <div className="flex-shrink-0 d-flex">
                              <div className="me-3">
                                <h5 className="font-size-14">Pan No.</h5>
                              </div>
                              <div className="flex-grow-1">
                                <div>{clientDetail && clientDetail.pan_no}</div>
                              </div>
                            </div>
                          </li>
                        }
                        <hr></hr>
                        {
                          <li>
                            <div className="flex-shrink-0 d-flex">
                              <div className="me-3">
                                <h5 className="font-size-14">Aadhar Card:</h5>
                              </div>
                              <div className="flex-grow-1">
                                <div>
                                  {" "}
                                  {clientDetail && clientDetail.aadhar_no}{" "}
                                </div>
                              </div>
                            </div>
                          </li>
                        }
                        <hr></hr>
                        {
                          <li>
                            <div className="flex-shrink-0 d-flex">
                              <div className="me-3">
                                <h5 className="font-size-14">Tan No.</h5>
                              </div>
                              <div className="flex-grow-1">
                                <div>{clientDetail && clientDetail.tan_no}</div>
                              </div>
                            </div>
                          </li>
                        }
                        <hr></hr>
                        {
                          <li>
                            <div className="flex-shrink-0 d-flex">
                              <div className="me-3">
                                <h5 className="font-size-14">GST No.</h5>
                              </div>
                              <div className="flex-grow-1">
                                <div>
                                  {clientDetail && clientDetail.gst_no}
                                </div>
                              </div>
                            </div>
                          </li>
                        }
                      </ul>
                    </CardBody>
                  </Card>
                </div>
              </SimpleBar>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default customerProfilePreview
