import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_COMPANY_FILTER,
  GET_COMPANY_LOCATION_FILTER,
  GET_CUSTOMER_FILTER,
  GET_PROJECT_FILTER,
  GET_FINANCIAL_YEAR_FILTER,
} from "./actionTypes"
import {
  getCompanyFilterSuccess,
  getCompanyFilterFail,
  getCompanyLocationFilterSuccess,
  getCompanyLocationFilterFail,
  getCustomerFilterSuccess,
  getCustomerFilterFail,
  getProjectFilterSuccess,
  getProjectFilterFail,
  getFinancialYearFilterSuccess,
  getFinancialYearFilterFail,
} from "./actions"

import {
  getCompanyFilterForHistory,
  getCompanyLocationFilterForHistory,
  getCustomerFilterForHistory,
  getProjectFilterForHistory,
  getFinancialYearFilterForHistory,
} from "helpers/backend"

function* fetchCompanyFilter({ userId }) {
  try {
    const response = yield call(getCompanyFilterForHistory, userId)
    yield put(getCompanyFilterSuccess(response))
  } catch (error) {
    yield put(getCompanyFilterFail(error))
  }
}
function* fetchCompanyLocationFilter({ userId }) {
  try {
    const response = yield call(getCompanyLocationFilterForHistory, userId)
    yield put(getCompanyLocationFilterSuccess(response))
  } catch (error) {
    yield put(getCompanyLocationFilterFail(error))
  }
}
function* fetchCustomerFilter({ userId }) {
  try {
    const response = yield call(getCustomerFilterForHistory, userId)
    yield put(getCustomerFilterSuccess(response))
  } catch (error) {
    yield put(getCustomerFilterFail(error))
  }
}
function* fetchProjectFilter({ userId }) {
  try {
    const response = yield call(getProjectFilterForHistory, userId)
    yield put(getProjectFilterSuccess(response))
  } catch (error) {
    yield put(getProjectFilterFail(error))
  }
}
function* fetchFinancialYearFilter({ userId }) {
  try {
    const response = yield call(getFinancialYearFilterForHistory, userId)
    yield put(getFinancialYearFilterSuccess(response))
  } catch (error) {
    yield put(getFinancialYearFilterFail(error))
  }
}

function* VendorPaymentSaga() {
  //Register filter
  yield takeEvery(GET_COMPANY_FILTER, fetchCompanyFilter)
  yield takeEvery(GET_COMPANY_LOCATION_FILTER, fetchCompanyLocationFilter)
  yield takeEvery(GET_CUSTOMER_FILTER, fetchCustomerFilter)
  yield takeEvery(GET_PROJECT_FILTER, fetchProjectFilter)
  yield takeEvery(GET_FINANCIAL_YEAR_FILTER, fetchFinancialYearFilter)
}

export default VendorPaymentSaga
