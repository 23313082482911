import {
  GET_PENDING_BILL_LIST_SUCCESS,
  GET_PENDING_BILL_LIST_FAIL,
  GET_BILL_HISTORY_LIST_SUCCESS,
  GET_BILL_HISTORY_LIST_FAIL,
  GET_BILL_RECEIPT_LIST_SUCCESS,
  GET_BILL_RECEIPT_LIST_FAIL,
  GET_RECEIPT_PREVIEW_SUCCESS,
  GET_RECEIPT_PREVIEW_FAIL,
  GET_CREDIT_NOTE_LIST_SUCCESS,
  GET_CREDIT_NOTE_LIST_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  pendingBillList: [],
  billHistoryList: [],
  billReceiptList: [],
  creditNoteList: [],
  billReceipt: {},
  error: {},
  loading: true,
}

const billAndPayments = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PENDING_BILL_LIST_SUCCESS:
      return {
        ...state,
        pendingBillList: action.payload,
        loading: false,
      }

    case GET_PENDING_BILL_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_BILL_RECEIPT_LIST_SUCCESS:
      return {
        ...state,
        billReceiptList: action.payload,
        loading: false,
      }

    case GET_BILL_RECEIPT_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_BILL_HISTORY_LIST_SUCCESS:
      return {
        ...state,
        billHistoryList: action.payload,
        loading: false,
      }

    case GET_BILL_HISTORY_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_RECEIPT_PREVIEW_SUCCESS:
      return {
        ...state,
        billReceipt: action.payload,
        loading: false,
      }

    case GET_RECEIPT_PREVIEW_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    //credit Note list
    case GET_CREDIT_NOTE_LIST_SUCCESS:
      return {
        ...state,
        creditNoteList: action.payload,
        loading: false,
      }

    case GET_CREDIT_NOTE_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default billAndPayments
