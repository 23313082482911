import React, { useEffect, useState } from "react"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  CardSubtitle,
} from "reactstrap"

import { Link, useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { getProjectUnitDetail } from "store/actions"

const ProjectUnitView = () => {
  const path = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const projectUnitDetailState = state =>
    state.projectUnits.projectUnitDetail.project_unit
  const projectUnit = useSelector(projectUnitDetailState) || []
  const [projectUnitId, setProjectUnitId] = useState()

  const fetchData = () => {
    const projectUnitId = path.pathname.split("/project-unit-view/")[1]
    setProjectUnitId(projectUnitId)
    dispatch(getProjectUnitDetail(projectUnitId))
  }

  useEffect(() => {
    fetchData()
  }, [dispatch, useLocation])

  return (
    <React.Fragment>
      <div className="page-content ">
        <Container fluid={true}>
          <Row className="justify-content-center">
            <Col lg={8}>
              <CardTitle className="h4 mb-3" style={{ fontSize: "20px" }}>
                <div className="d-flex gap-2 ">
                  <i
                    className="mdi mdi-arrow-left font-size-20"
                    style={{ color: "grey" }}
                    onClick={() => {
                      navigate(-1)
                    }}
                  ></i>
                  Project Unit Details
                </div>
              </CardTitle>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Label>Project</Label>
                      <p>{projectUnit && projectUnit.project_name}</p>
                    </Col>
                    <Col>
                      <Label>Project Unit</Label>
                      <p>{projectUnit && projectUnit.unit_code}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label>Minimum Monthly Rent</Label>
                      <p>{projectUnit && projectUnit.minimum_monthly_rent}</p>
                    </Col>
                    <Col>
                      <Label>Revenue Share</Label>
                      <p>
                        {projectUnit && projectUnit.revenue_share === 1
                          ? "Monthly"
                          : "Annualy"}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label>Revenue Share(%)</Label>
                      <p>
                        {projectUnit && projectUnit.revenue_share_percentage}
                      </p>
                    </Col>
                    <Col>
                      <Label>Rent Payment Schedule</Label>
                      <p>
                        {projectUnit && projectUnit?.rent_payment_schedule && (
                          <span>
                            {projectUnit.rent_payment_schedule === 1
                              ? "Monthly"
                              : projectUnit.rent_payment_schedule === 2
                              ? "Quaterly"
                              : projectUnit.rent_payment_schedule === 3
                              ? "Half Yearly"
                              : projectUnit.rent_payment_schedule === 4
                              ? "Yearly"
                              : ""}
                          </span>
                        )}{" "}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label>Customer Lock-in Period</Label>
                      <p>
                        {projectUnit && projectUnit.customer_lock_in_period}
                      </p>
                    </Col>
                    <Col>
                      <Label>Notice Period</Label>
                      <p>{projectUnit && projectUnit.notice_period}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label>Security Deposit For Rent</Label>
                      <p>
                        {projectUnit && projectUnit.security_deposit_for_rent}
                      </p>
                    </Col>
                    <Col>
                      <Label>Security Deposit For Electricity</Label>
                      <p>
                        {projectUnit &&
                          projectUnit.security_deposit_for_electricity}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label>Security Deposit For Maintenance</Label>
                      <p>
                        {projectUnit &&
                          projectUnit.security_deposit_for_maintenance}
                      </p>
                    </Col>
                    <Col>
                      <Label>Agreement Start Date</Label>
                      <p>{projectUnit && projectUnit.agreement_start_date}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label>Agreement End Date</Label>
                      <p>{projectUnit && projectUnit.agreement_end_date}</p>
                    </Col>
                    <Col>
                      <Label>Rent Escalation(%)</Label>
                      <p>
                        {projectUnit && projectUnit.rent_escalation_percentage}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label>Registration cost for agreement on company</Label>
                      <p>
                        {projectUnit &&
                          projectUnit.registration_cost_for_agreement_on_company}
                      </p>
                    </Col>
                    <Col>
                      <Label>Rent Escalation Period (Months)</Label>
                      <p>{projectUnit && projectUnit.rent_escalation_period}</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProjectUnitView
