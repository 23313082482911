import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"

import { Card, Col, CardBody, Row, CardTitle } from "reactstrap"
import { getCountForDashboard, getServiceRequest } from "store/actions"

const ServiceRequest = () => {
  

  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const [clientId, setClientId] = useState(authUser ? authUser.id : "")
  const dispatch = useDispatch()
  const serviceRequestState = state =>
    state.Dashboard.serviceRequest.service_requests
  const serviceRequest = useSelector(serviceRequestState) || []

  console.log('serviceRequest',serviceRequest)
  useEffect(() => {
    dispatch(getServiceRequest({client_id : clientId,user_type:authUser.user_type}))
  }, [dispatch])

  return (
    <React.Fragment>
       <Row> 
      <Row>
        <Col>
          <CardTitle > Service Request </CardTitle>
        </Col>
        <Col className="text-end">
          <Link to='/service-requests/all-service-requests' > View all </Link>
        </Col>
      </Row>
      {serviceRequest && serviceRequest.slice(0, 3).map((request, index) => (
  <Col lg={4} key={index}>
    <Card className="blog-stats-wid">
      <Link to={`/service-requests/view-service-requests/${request.id}`}>
        <CardBody>
          <div className="d-flex flex-wrap">
            <div className="me-3">
              <h6 className="mb-0 text-dark mb-2" >{request.service_type}</h6>
              <p className="text-muted mb-2">{request.srq_code}</p>
              <p className="text-muted mb-2">{request.created_on}</p>
              <p className={request.status === 1 ? 'text-primary' : request.status === 2 ? 'text-success' : request.status === 3 ? 'text-secondary' : request.status === 4 ? 'text-warning' : "text-muted "}>{request.status_name}</p>
            </div>
            
          </div>
        </CardBody>
      </Link>
    </Card>
  </Col>
))}

      
      </Row>
    </React.Fragment>
  )
}

export default ServiceRequest
