import React, { useEffect, useState } from "react"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  CardSubtitle,
} from "reactstrap"

import { Link, useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { getProjectUnitDetail } from "store/actions"

const Helpdesk = () => {
  const path = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // const projectUnitDetailState = state =>
  //   state.projectUnits.projectUnitDetail.project_unit
  // const data = useSelector(projectUnitDetailState) || []
  // const [projectUnitId, setProjectUnitId] = useState()

  // const fetchData = () => {
  //   const projectUnitId = path.pathname.split("/project-unit-view/")[1]
  //   setProjectUnitId(projectUnitId)
  //   dispatch(getProjectUnitDetail(projectUnitId))
  // }

  // useEffect(() => {
  //   fetchData()
  // }, [dispatch, useLocation])

  const [isOpen1, setIsOpen1] = useState(false)

  const toggleVisibility1 = () => {
    setIsOpen1(!isOpen1)
  }
  const [isOpen2, setIsOpen2] = useState(false)

  const toggleVisibility2 = () => {
    setIsOpen2(!isOpen2)
  }
  const [isOpen3, setIsOpen3] = useState(false)

  const toggleVisibility3 = () => {
    setIsOpen3(!isOpen3)
  }
  const [isOpen4, setIsOpen4] = useState(false)

  const toggleVisibility4 = () => {
    setIsOpen4(!isOpen4)
  }
  const [isOpen5, setIsOpen5] = useState(false)

  const toggleVisibility5 = () => {
    setIsOpen5(!isOpen5)
  }

  return (
    <React.Fragment>
      <div className="page-content ">
        <Container fluid={true}>
          <Row className="justify-content-center">
            <Col lg={5}>
              <CardTitle className="h4 mb-3" style={{ fontSize: "20px" }}>
                Contact Us
              </CardTitle>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Row className="mb-2">
                        <Col lg={6}>
                          <Label className="form-label ">Phone No.*</Label>
                        </Col>
                        <Col lg={6}>
                          <p className="text-dark text-muted">
                            +91 9987569965 / 022 2345 6789
                          </p>
                        </Col>
                      </Row>
                      <hr />
                      <Row className="mb-2">
                        <Col lg={6}>
                          <Label className="form-label ">Email address*</Label>
                        </Col>
                        <Col lg={6}>
                          <p className="text-dark text-muted">
                            care@vipmall.com
                          </p>
                        </Col>
                      </Row>
                      <hr />
                      <Row className="mb-2">
                        <Col lg={6}>
                          <Label className="form-label ">Address*</Label>
                        </Col>
                        <Col lg={6}>
                          <p className="text-dark text-muted">
                            28, Dr Ernest Borges Rd, Opp Dr. Shirodkar High
                            School, Parel East, Parel, Mumbai, Maharashtra
                            400012,India
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={5}>
              <CardTitle className="h4 mb-3 ms-2" style={{ fontSize: "20px" }}>
                FAQ's
              </CardTitle>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Row className="">
                        <Col lg={6}>
                          <Label className="form-label ">
                            <h5>FAQ Question 1* </h5>
                          </Label>
                        </Col>
                        <Col lg={6} className="text-end">
                          <span
                            className="toggle-icon font-size-24"
                            onClick={toggleVisibility1}
                            style={{cursor:"pointer"}}
                          >
                            {isOpen1 ? "-" : "+"}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <div
                    className={`hidden-content ${
                      isOpen1 ? "visible" : "hidden"
                    }`}
                    style={{
                      transition: "max-height 0.3s ease-in-out",
                      maxHeight: isOpen1 ? "1000px" : "0",
                      overflow: "hidden",
                    }}
                  >
                    <Row>
                      <Col>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.</p>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Row className="">
                        <Col lg={6}>
                          <Label className="form-label ">
                            <h5>FAQ Question 2* </h5>
                          </Label>
                        </Col>
                        <Col lg={6} className="text-end">
                          <span
                            className="toggle-icon font-size-24"
                            onClick={toggleVisibility2}
                            style={{cursor:"pointer"}}
                          >
                            {isOpen2 ? "-" : "+"}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <div
                    className={`hidden-content ${
                      isOpen2 ? "visible" : "hidden"
                    }`}
                    style={{
                      transition: "max-height 0.3s ease-in-out",
                      maxHeight: isOpen2 ? "1000px" : "0",
                      overflow: "hidden",
                    }}
                  >
                    <Row>
                      <Col>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.</p>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Row className="">
                        <Col lg={6}>
                          <Label className="form-label ">
                            <h5>FAQ Question 3* </h5>
                          </Label>
                        </Col>
                        <Col lg={6} className="text-end">
                          <span
                            className="toggle-icon font-size-24"
                            onClick={toggleVisibility3}
                            style={{cursor:"pointer"}}
                          >
                            {isOpen3 ? "-" : "+"}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <div
                    className={`hidden-content ${
                      isOpen3 ? "visible" : "hidden"
                    }`}
                    style={{
                      transition: "max-height 0.3s ease-in-out",
                      maxHeight: isOpen3 ? "1000px" : "0",
                      overflow: "hidden",
                    }}
                  >
                    <Row>
                      <Col>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.</p>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Row className="">
                        <Col lg={6}>
                          <Label className="form-label ">
                            <h5>FAQ Question 4* </h5>
                          </Label>
                        </Col>
                        <Col lg={6} className="text-end">
                          <span
                            className="toggle-icon font-size-24"
                            onClick={toggleVisibility4}
                            style={{cursor:"pointer"}}
                          >
                            {isOpen4 ? "-" : "+"}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <div
                    className={`hidden-content ${
                      isOpen4 ? "visible" : "hidden"
                    }`}
                    style={{
                      transition: "max-height 0.3s ease-in-out",
                      maxHeight: isOpen4 ? "1000px" : "0",
                      overflow: "hidden",
                    }}
                  >
                    <Row>
                      <Col>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.</p>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Row className="">
                        <Col lg={6}>
                          <Label className="form-label ">
                            <h5>FAQ Question 5* </h5>
                          </Label>
                        </Col>
                        <Col lg={6} className="text-end">
                          <span
                            className="toggle-icon font-size-24"
                            onClick={toggleVisibility5}
                            style={{cursor:"pointer"}}
                          >
                            {isOpen5 ? "-" : "+"}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <div
                    className={`hidden-content ${
                      isOpen5 ? "visible" : "hidden"
                    }`}
                    style={{
                      transition: "max-height 0.3s ease-in-out",
                      maxHeight: isOpen5 ? "1000px" : "0",
                      overflow: "hidden",
                    }}
                  >
                    <Row>
                      <Col>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi.</p>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Helpdesk
