import {
  GET_LATEST_INVOICE_SUCCESS,
  GET_LATEST_INVOICE_FAIL,
  GET_PREVIOUS_INVOICES_LIST_SUCCESS,
  GET_PREVIOUS_INVOICES_LIST_FAIL,
  GET_SERVICE_REQUEST_SUCCESS,
  GET_SERVICE_REQUEST_FAIL,
  GET_INVOICE_DATA_SUCCESS,
  GET_INVOICE_DATA_FAIL,
  GET_WATER_CONSUMPTION_SUCCESS,
  GET_WATER_CONSUMPTION_FAIL,
  GET_ELECTRICITY_CONSUMPTION_SUCCESS,
  GET_ELECTRICITY_CONSUMPTION_FAIL,
  GET_GAS_CONSUMPTION_FAIL,
  GET_GAS_CONSUMPTION_SUCCESS,
  GET_DG_CONSUMPTION_SUCCESS,
  GET_DG_CONSUMPTION_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  latestInvoice: [],
  invoiceData: [],
  previousInvoicesList: [],
  serviceRequest: [],
  error: {},
  gas: {},
  dg: {},
  electricity: {},
  water: {},
  loading: true,
}

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LATEST_INVOICE_SUCCESS:
      return {
        ...state,
        latestInvoice: action.payload,
        loading: false,
      }

    case GET_LATEST_INVOICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_INVOICE_DATA_SUCCESS:
      return {
        ...state,
        invoiceData: action.payload,
        loading: false,
      }

    case GET_INVOICE_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PREVIOUS_INVOICES_LIST_SUCCESS:
      return {
        ...state,
        previousInvoicesList: action.payload,
        loading: false,
      }

    case GET_PREVIOUS_INVOICES_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_SERVICE_REQUEST_SUCCESS:
      return {
        ...state,
        serviceRequest: action.payload,
        loading: false,
      }

    case GET_SERVICE_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      //graph for consumption
      
    case GET_WATER_CONSUMPTION_SUCCESS:
      return {
        ...state,
        water: action.payload,
        loading: false,
      }

    case GET_WATER_CONSUMPTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ELECTRICITY_CONSUMPTION_SUCCESS:
      return {
        ...state,
        electricity: action.payload,
        loading: false,
      }

    case GET_ELECTRICITY_CONSUMPTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_GAS_CONSUMPTION_SUCCESS:
      return {
        ...state,
        gas: action.payload,
        loading: false,
      }

    case GET_GAS_CONSUMPTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_DG_CONSUMPTION_SUCCESS:
      return {
        ...state,
        dg: action.payload,
        loading: false,
      }

    case GET_DG_CONSUMPTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Dashboard
