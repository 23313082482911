import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import TableContainer from "./billReceiptListContainer"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap"

// import { Email, Tags, Projects } from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"
import {
  getBillHistoryList,
  getBillReceiptList,
} from "store/bills&payment/actions"
import handleDownloadInvoice from "components/Common/handleDownloadInvoice"
import LargeModal from "VIP PAGES/Dashboard/viewInvoiceModal"
import ReceiptPreviewModal from "./receiptPreviewModal"
import { API_URL } from "helpers/url"

const BillReceiptList = () => {
  const navigate = useNavigate()
  document.title = "Bill History List "
  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const [clientId, setClientId] = useState(authUser ? authUser.id : "")
  const dispatch = useDispatch()
  const [modalLarge1, setModalLarge1] = useState(false)

  const toggleModalLarge1 = () => setModalLarge1(!modalLarge1)

  const billHistoryListState = state =>
    state.billAndPayments.billReceiptList.data

  const loadingState = state => state.billAndPayments.loading
  const billHistory = useSelector(billHistoryListState) || []
  const loading = useSelector(loadingState) || []
  console.log(billHistory)
  const [isLoading, setLoading] = useState(loading)

  useEffect(() => {
    if (clientId) {
      dispatch(
        getBillReceiptList({
          client_id: clientId,
          user_type: authUser.user_type,
        })
      )
    }
  }, [clientId])

  const [modalLarge, setModalLarge] = useState(false)

  const toggleModalLarge = () => setModalLarge(!modalLarge)
  const [billingId, setBillingId] = useState()

  const handleBillPreview = id => {
    setBillingId(id)
    toggleModalLarge()
  }

  const [Id, setId] = useState()
  const handleReceiptModal = (receipt, Id) => {
    setId(Id)
    toggleModalLarge1()
  }

  const handleDownloadReceipt = paymentId => {
    // Construct the URL for download
    const downloadUrl = `${API_URL}/api/download-receipt-invoice-pdf/${paymentId}`

    // Create an anchor element
    const anchorElement = document.createElement("a")
    anchorElement.href = downloadUrl
    anchorElement.target = "_blank" // Open in a new tab
    anchorElement.download = "receipt.pdf"

    // Append the anchor element to the document body (required in some browsers)
    document.body.appendChild(anchorElement)

    // Trigger a click event on the anchor element
    anchorElement.click()

    // Clean up: remove the anchor element from the document body
    document.body.removeChild(anchorElement)
  }

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "srNo",
        sortBy: true,
        // filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {/* <Link className="text-dark" to="#">#00001</Link> */}
                <Link className="text-dark" to="#">
                  {" "}
                  {cellProps.row.index + 1}
                </Link>
              </h5>
            </>
          )
        },
      },

      {
        Header: "Receipt No",
        accessor: "receipt_no",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.receipt_no}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Date",
        accessor: "receipt_date",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.receipt_date}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Amount",
        accessor: "amount",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.amount}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: (
          <div style={{ whiteSpace: "nowrap" }}>
            <span style={{ display: "block" }}>Project</span>
            <span style={{ display: "block" }}>Name</span>
          </div>
        ),
        accessor: "project_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.project_name}
                </Link>
              </h5>
            </>
          )
        },
      },

      {
        Header: "Company",
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.company_name}
                </Link>
              </h5>
            </>
          )
        },
      },

      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <a
                onClick={() => {
                  handleDownloadReceipt(cellProps.row.original.payment_id)
                }}
              >
                <i
                  className="mdi mdi-download font-size-18"
                  id={`downloadleaseagreementtooltip`}
                  style={{ color: "#3366cc" }}
                />
                <UncontrolledTooltip
                  placement="top"
                  target={`downloadleaseagreementtooltip`}
                >
                  Download
                </UncontrolledTooltip>
              </a>
              <a
                onClick={() => {
                  handleReceiptModal(
                    cellProps.row.original,
                    cellProps.row.original.payment_id
                  )
                }}
              >
                <i
                  className="mdi mdi-eye font-size-18"
                  id={`viewleaseagreementtooltip-1`}
                  style={{ color: "#3366cc" }}
                />
                <UncontrolledTooltip
                  placement="top"
                  target={`viewleaseagreementtooltip-1`}
                >
                  View
                </UncontrolledTooltip>
              </a>
            </div>
          )
        },
      },
    ],
    []
  )

  const handleUserClick = arg => {
    const user = arg
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = users => {
    setDeleteId({ id: users })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (deleteId && deleteId.id) {
      dispatch(deletePendingBills(deleteId.id))
    }
    onPaginationPageChange(1)
    setDeleteModal(false)
    navigate("/pendingBills")
    dispatch(getPendingBillsList())
  }

  const handleUserClicks = () => {
    setUserList("")
    toggle()
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <ReceiptPreviewModal
        isOpen={modalLarge1}
        toggle={toggleModalLarge1}
        Id={Id}
      />
      <LargeModal
        isOpen={modalLarge}
        toggle={toggleModalLarge}
        billingId={billingId}
      />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Receipt" breadcrumbItem="Receipt" />

          <Row>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      isPagination={true}
                      columns={columns}
                      data={billHistory}
                      isGlobalFilter={true}
                      isShowingPageLength={true}
                      isAddUserList={true}
                      iscustomPageSizeOptions={true}
                      handleUserClick={handleUserClicks}
                      customPageSize={10}
                      tableClass="table align-middle table-nowrap table-hover"
                      theadClass="table-light"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination pagination-rounded justify-content-end mt-4"
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BillReceiptList
