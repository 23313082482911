import {
  API_SUCCESS,
  API_FAIL,
  GET_CHARTS_DATA,
  GET_LATEST_INVOICE,
  GET_LATEST_INVOICE_SUCCESS,
  GET_LATEST_INVOICE_FAIL,
  GET_PREVIOUS_INVOICES_LIST,
  GET_PREVIOUS_INVOICES_LIST_SUCCESS,
  GET_PREVIOUS_INVOICES_LIST_FAIL,
  GET_SERVICE_REQUEST,
  GET_SERVICE_REQUEST_SUCCESS,
  GET_SERVICE_REQUEST_FAIL,
  GET_INVOICE_DATA_SUCCESS,
  GET_INVOICE_DATA_FAIL,
  GET_INVOICE_DATA,
  GET_WATER_CONSUMPTION,
  GET_WATER_CONSUMPTION_SUCCESS,
  GET_WATER_CONSUMPTION_FAIL,
  GET_GAS_CONSUMPTION,
  GET_GAS_CONSUMPTION_SUCCESS,
  GET_GAS_CONSUMPTION_FAIL,
  GET_ELECTRICITY_CONSUMPTION,
  GET_ELECTRICITY_CONSUMPTION_SUCCESS,
  GET_ELECTRICITY_CONSUMPTION_FAIL,
  GET_DG_CONSUMPTION,
  GET_DG_CONSUMPTION_SUCCESS,
  GET_DG_CONSUMPTION_FAIL,
} from "./actionTypes"

export const apiSuccess = (actionType, data) => ({
  type: API_SUCCESS,
  payload: { actionType, data },
})

export const apiFail = (actionType, error) => ({
  type: API_FAIL,
  payload: { actionType, error },
})

// charts data
export const getChartsData = periodType => ({
  type: GET_CHARTS_DATA,
  payload: periodType,
})

export const getLatestInvoice = clientId => ({
    type: GET_LATEST_INVOICE,
    clientId,
  })
  
  export const getLatestInvoiceSuccess = latestInvoice => ({
    type: GET_LATEST_INVOICE_SUCCESS,
    payload: latestInvoice,
  })
  
  export const getLatestInvoiceFail = error => ({
    type: GET_LATEST_INVOICE_FAIL,
    payload: error,
  })
export const getPreviousInvoicesList = clientId => ({
    type: GET_PREVIOUS_INVOICES_LIST,
    clientId,
  })
  
  export const getPreviousInvoicesListSuccess = previousInvoicesList => ({
    type: GET_PREVIOUS_INVOICES_LIST_SUCCESS,
    payload: previousInvoicesList,
  })
  
  export const getPreviousInvoicesListFail = error => ({
    type: GET_PREVIOUS_INVOICES_LIST_FAIL,
    payload: error,
  })
export const getServiceRequest = clientId => ({
    type: GET_SERVICE_REQUEST,
    clientId,
  })
  
  export const getServiceRequestSuccess = serviceRequest => ({
    type: GET_SERVICE_REQUEST_SUCCESS,
    payload: serviceRequest,
  })
  
  export const getServiceRequestFail = error => ({
    type: GET_SERVICE_REQUEST_FAIL,
    payload: error,
  })
  

  export const getInvoiceData = billingId => ({
    type: GET_INVOICE_DATA,
    billingId,
  })
  
  export const getInvoiceDataSuccess = latestInvoice => ({
    type: GET_INVOICE_DATA_SUCCESS,
    payload: latestInvoice,
  })
  
  export const getInvoiceDataFail = error => ({
    type: GET_INVOICE_DATA_FAIL,
    payload: error,
    })
///dashboard graphs
  export const getWaterConsumption = data => ({
    type: GET_WATER_CONSUMPTION,
    data,
  })
  
  export const getWaterConsumptionSuccess = latestInvoice => ({
    type: GET_WATER_CONSUMPTION_SUCCESS,
    payload: latestInvoice,
  })
  
  export const getWaterConsumptionFail = error => ({
    type: GET_WATER_CONSUMPTION_FAIL,
    payload: error,
  })

  export const getElectricityConsumption = data => ({
    type: GET_ELECTRICITY_CONSUMPTION,
    data,
  })
  
  export const getElectricityConsumptionSuccess = latestInvoice => ({
    type: GET_ELECTRICITY_CONSUMPTION_SUCCESS,
    payload: latestInvoice,
  })
  
  export const getElectricityConsumptionFail = error => ({
    type: GET_ELECTRICITY_CONSUMPTION_FAIL,
    payload: error,
  })

  export const getGasConsumption = data => ({
    type: GET_GAS_CONSUMPTION,
    data,
  })
  
  export const getGasConsumptionSuccess = latestInvoice => ({
    type: GET_GAS_CONSUMPTION_SUCCESS,
    payload: latestInvoice,
  })
  
  export const getGasConsumptionFail = error => ({
    type: GET_GAS_CONSUMPTION_FAIL,
    payload: error,
  })
  export const getDgConsumption = data => ({
    type: GET_DG_CONSUMPTION,
    data,
  })
  
  export const getDgConsumptionSuccess = latestInvoice => ({
    type: GET_DG_CONSUMPTION_SUCCESS,
    payload: latestInvoice,
  })
  
  export const getDgConsumptionFail = error => ({
    type: GET_DG_CONSUMPTION_FAIL,
    payload: error,
  })