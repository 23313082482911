import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./login/reducer"
import common from "./common/reducer"
import Profile from "./profile/reducer"
import projectUnits from "./projectUnits/reducer"
import serviceRequests from "./serviceRequests/reducer"
import billAndPayments from "./bills&payment/reducer"
import customerProfilePreview from "./customerProfilePreview/reducer"
import Dashboard from "./dashboard/reducer"
import forgetPassword from "./forgetpwd/reducer"
import vendorPayment from "./bills&payment/paymentFilterForHistory/reducer"
import consumptionReport from "./consumptionReport/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  forgetPassword,
  common,
  Profile,
  projectUnits,
  serviceRequests,
  billAndPayments,
  customerProfilePreview,
  Dashboard,
  vendorPayment,
  consumptionReport,
})

export default rootReducer
