import {
  GET_PENDING_BILL_LIST,
  GET_PENDING_BILL_LIST_SUCCESS,
  GET_PENDING_BILL_LIST_FAIL,
  GET_BILL_HISTORY_LIST,
  GET_BILL_HISTORY_LIST_SUCCESS,
  GET_BILL_HISTORY_LIST_FAIL,
  GET_BILL_RECEIPT_LIST,
  GET_BILL_RECEIPT_LIST_SUCCESS,
  GET_BILL_RECEIPT_LIST_FAIL,
  GET_RECEIPT_PREVIEW,
  GET_RECEIPT_PREVIEW_SUCCESS,
  GET_RECEIPT_PREVIEW_FAIL,
  GET_CREDIT_NOTE_LIST,
  GET_CREDIT_NOTE_LIST_SUCCESS,
  GET_CREDIT_NOTE_LIST_FAIL,
} from "./actionTypes"


export const getPendingBillList = clientId => ({
  type: GET_PENDING_BILL_LIST,
  clientId,
})

export const getPendingBillListSuccess = pendingBillList => ({
  type: GET_PENDING_BILL_LIST_SUCCESS,
  payload: pendingBillList,
})

export const getPendingBillListFail = error => ({
  type: GET_PENDING_BILL_LIST_FAIL,
  payload: error,
})

export const getBillHistoryList = clientId => ({
  type: GET_BILL_HISTORY_LIST,
  clientId,
})

export const getBillHistoryListSuccess = billHistoryList => ({
  type: GET_BILL_HISTORY_LIST_SUCCESS,
  payload: billHistoryList,
})

export const getBillHistoryListFail = error => ({
  type: GET_BILL_HISTORY_LIST_FAIL,
  payload: error,
})

export const getBillReceiptList = clientId => ({
  type: GET_BILL_RECEIPT_LIST,
  clientId,
})

export const getBillReceiptListSuccess = billReceiptList => ({
  type: GET_BILL_RECEIPT_LIST_SUCCESS,
  payload: billReceiptList,
})

export const getBillReceiptListFail = error => ({
  type: GET_BILL_RECEIPT_LIST_FAIL,
  payload: error,
})

export const getReceiptPreview = clientId => ({
  type: GET_RECEIPT_PREVIEW,
  clientId,
})

export const getReceiptPreviewSuccess = billReceipt => ({
  type: GET_RECEIPT_PREVIEW_SUCCESS,
  payload: billReceipt,
})

export const getReceiptPreviewFail = error => ({
  type: GET_RECEIPT_PREVIEW_FAIL,
  payload: error,
})

//credit note
export const getCreditNoteList = clientId => ({
  type: GET_CREDIT_NOTE_LIST,
  clientId,
})

export const getCreditNoteListSuccess = creditNoteList => ({
  type: GET_CREDIT_NOTE_LIST_SUCCESS,
  payload: creditNoteList,
})

export const getCreditNoteListFail = error => ({
  type: GET_CREDIT_NOTE_LIST_FAIL,
  payload: error,
})