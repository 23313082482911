import { call, put, takeEvery } from "redux-saga/effects"
import {
  GET_DG_CONSUMPTION,
  GET_ELECTRICITY_CONSUMPTION,
  GET_GAS_CONSUMPTION,
  GET_INVOICE_DATA,
  GET_LATEST_INVOICE,
  GET_PREVIOUS_INVOICES_LIST,
  GET_SERVICE_REQUEST,
  GET_WATER_CONSUMPTION,
} from "./actionTypes"
import {
  getLatestInvoiceSuccess,
  getLatestInvoiceFail,
  getPreviousInvoicesListSuccess,
  getPreviousInvoicesListFail,
  getServiceRequestSuccess,
  getServiceRequestFail,
  getInvoiceDataSuccess,
  getInvoiceDataFail,
  getWaterConsumptionSuccess,
  getWaterConsumptionFail,
  getElectricityConsumptionSuccess,
  getElectricityConsumptionFail,
  getGasConsumptionSuccess,
  getGasConsumptionFail,
  getDgConsumptionSuccess,
  getDgConsumptionFail,
} from "./actions"

import {
  getDgConsumption,
  getElectricityConsumption,
  getGasConsumption,
  getInvoiceData,
  getLatestInvoice,
  getPreviousInvoicesList,
  getServiceRequest,
  getWaterConsumption,
} from "helpers/backend"

function* fetchLatestInvoice({ clientId }) {
  try {
    const response = yield call(getLatestInvoice, clientId)
    yield put(getLatestInvoiceSuccess(response))
  } catch (error) {
    yield put(getLatestInvoiceFail(error))
  }
}
function* fetchInvoiceData({ billingId }) {
  try {
    const response = yield call(getInvoiceData, billingId)
    yield put(getInvoiceDataSuccess(response))
  } catch (error) {
    yield put(getInvoiceDataFail(error))
  }
}


function* fetchPreviousInvoicesList({ clientId }) {
  try {
    const response = yield call(getPreviousInvoicesList, clientId)
    yield put(getPreviousInvoicesListSuccess(response))
  } catch (error) {
    yield put(getPreviousInvoicesListFail(error))
  }
}
function* fetchServiceRequest({ clientId }) {
  try {
    const response = yield call(getServiceRequest, clientId)
    yield put(getServiceRequestSuccess(response))
  } catch (error) {
    yield put(getServiceRequestFail(error))
  }
}
//consumption graph
function* fetchWaterConsumption({ data }) {
  try {
    const response = yield call(getWaterConsumption, data)
    yield put(getWaterConsumptionSuccess(response))
  } catch (error) {
    yield put(getWaterConsumptionFail(error))
  }
}
function* fetchElectricityConsumption({ data }) {
  try {
    const response = yield call(getElectricityConsumption, data)
    yield put(getElectricityConsumptionSuccess(response))
  } catch (error) {
    yield put(getElectricityConsumptionFail(error))
  }
}
function* fetchGasConsumption({ data }) {
  try {
    const response = yield call(getGasConsumption, data)
    yield put(getGasConsumptionSuccess(response))
  } catch (error) {
    yield put(getGasConsumptionFail(error))
  }
}
function* fetchDgConsumption({ data }) {
  try {
    const response = yield call(getDgConsumption, data)
    yield put(getDgConsumptionSuccess(response))
  } catch (error) {
    yield put(getDgConsumptionFail(error))
  }
}

function* dashboardSaga() {
  yield takeEvery(GET_LATEST_INVOICE, fetchLatestInvoice)
  yield takeEvery(GET_INVOICE_DATA, fetchInvoiceData)
  yield takeEvery(GET_PREVIOUS_INVOICES_LIST, fetchPreviousInvoicesList)
yield takeEvery(GET_SERVICE_REQUEST, fetchServiceRequest)
yield takeEvery(GET_WATER_CONSUMPTION, fetchWaterConsumption)
yield takeEvery(GET_ELECTRICITY_CONSUMPTION, fetchElectricityConsumption)
yield takeEvery(GET_GAS_CONSUMPTION, fetchGasConsumption)
yield takeEvery(GET_DG_CONSUMPTION, fetchDgConsumption)
  }

export default dashboardSaga
