import React, { useEffect, useState } from "react"
import SimpleBar from "simplebar-react"
// import { activityData } from "common/data"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  CardSubtitle,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Alert,
} from "reactstrap"

import { Link, useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { getServiceRequestDetail } from "store/actions"
import Spinners from "components/Common/Spinner"

const ServiceRequestView = () => {
  const path = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const serviceRequestDetailState = state =>
    state.serviceRequests.serviceRequestDetail.service_request
  const serviceRequestLogState = state =>
    state.serviceRequests.serviceRequestDetail.service_request_log
  const ticketFinalDocument = state =>
    state.serviceRequests.serviceRequestDetail?.ticket_files
  const loadingState = state => state.serviceRequests.loading
  const loading = useSelector(loadingState) || []
  const [isLoading, setLoading] = useState(loading)
  const data = useSelector(serviceRequestDetailState) || []
  const finalDocument = useSelector(ticketFinalDocument) || []
  const trackingData = useSelector(serviceRequestLogState) || []
  const [serviceRequestId, setServiceRequestId] = useState()

  const fetchData = () => {
    const serviceRequestId = path.pathname.split("/view-service-requests/")[1]
    setServiceRequestId(serviceRequestId)
    dispatch(getServiceRequestDetail(serviceRequestId))
  }

  useEffect(() => {
    fetchData()
  }, [dispatch, useLocation])

  const getStatusColor = status => {
    switch (status) {
      case "Open":
        return "text-secondary" // Primary color
      case "Ticket Created":
        return "text-primary" // Success color
      case "In Progress":
        return "text-warning" // Warning color
      case "In Progress (reassigned)":
        return "text-warning" // Warning color
      case "Closed":
        return "text-success" // Danger color
      case "Declined(with reason)":
        return "text-danger" // Danger color
      default:
        return ""
    }
  }

  return (
    <React.Fragment>
      {isLoading ? (
        <Spinners setLoading={setLoading} />
      ) : (
        <div className="page-content ">
          <Container fluid={true}>
            <Row className="justify-content-center">
              <Col lg={12}>
                <CardTitle className="h4 mb-3" style={{ fontSize: "20px" }}>
                  <div className="d-flex gap-2 ">
                    <i
                      className="mdi mdi-arrow-left font-size-20"
                      style={{ color: "grey" }}
                      onClick={() => {
                        navigate(-1)
                      }}
                    ></i>
                    Service Request Details
                  </div>
                </CardTitle>
                {data && data.status === 5 && (
                  <Col lg={12}>
                    <Label></Label>
                    <Alert color="success" role="alert" className="text-center">
                      Service Request Has Been Declined
                    </Alert>
                  </Col>
                )}
                <Card>
                  <CardBody>
                    <div className="d-flex align-items-start">
                      <div className="me-2">
                        <h5 className="card-title mb-4">
                          Track Request ({data.srq_code})
                        </h5>
                      </div>
                    </div>
                    <SimpleBar className="mt-2" style={{ maxHeight: "280px" }}>
                      <ul className="verti-timeline list-unstyled">
                        {data && data.created_on && (
                          <li className={`event-list ${"active"}`}>
                            <div className="event-timeline-dot">
                              <i
                                className={`bx bxs-right-arrow-circle font-size-18 bx-fade-right
                                  `}
                              ></i>
                            </div>
                            <div className="d-flex">
                              <div className="flex-shrink-0 me-3">
                                <h5 className="font-size-14">
                                  Service Request has been Created(
                                  {data.created_on})
                                </h5>
                              </div>
                            </div>
                          </li>
                        )}
                        {data && data.ticket_created_on && (
                          <li className={`event-list ${"active"}`}>
                            <div className="event-timeline-dot">
                              <i
                                className={`bx bxs-right-arrow-circle font-size-18 bx-fade-right
                                  `}
                              ></i>
                            </div>
                            <div className="d-flex">
                              <div className="flex-shrink-0 me-3">
                                <h5 className="font-size-14">
                                  Service Request Converted to Ticket(
                                  {data.ticket_created_on})
                                </h5>
                              </div>
                            </div>
                          </li>
                        )}
                        {data && data.ticket_closed_on && (
                          <li className={`event-list ${"active"}`}>
                            <div className="event-timeline-dot">
                              <i
                                className={`bx bxs-right-arrow-circle font-size-18 bx-fade-right
                                  `}
                              ></i>
                            </div>
                            <div className="d-flex">
                              <div className="flex-shrink-0 me-3">
                                <h5 className="font-size-14">
                                  Ticket Closed(
                                  {data.ticket_closed_on})
                                </h5>
                              </div>
                            </div>
                          </li>
                        )}
                      </ul>
                    </SimpleBar>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg={4}>
                        <h6>Type of Service</h6>
                      </Col>
                      <Col lg={8}>{data && data.service_type}</Col>
                    </Row>
                    <hr></hr>
                    {data && data.category_title !== null && (
                      <>
                        {" "}
                        <Row>
                          <Col lg={4}>
                            <h6>Project Unit Category</h6>
                          </Col>
                          <Col lg={8}>
                            {data && data.category_title === null
                              ? "NA"
                              : data.category_title}
                          </Col>
                        </Row>
                        <hr />
                      </>
                    )}
                    {data && data.maintenance_issue_type !== null && (
                      <>
                        <Row>
                          <Col lg={4}>
                            <h6>Maintainence Issue</h6>
                          </Col>
                          <Col lg={8}>
                            {data && data.maintenance_issue_type === null
                              ? "NA"
                              : data.maintenance_issue_type}
                          </Col>
                        </Row>
                        <hr />
                      </>
                    )}
                    {data && data.project_name !== null && (
                      <>
                        <Row>
                          <Col lg={4}>
                            <h6>Project In Which You Are Facing Issue</h6>
                          </Col>
                          <Col lg={8}>
                            {data && data.project_name === null
                              ? "NA"
                              : data.project_name}
                          </Col>
                        </Row>
                        <hr />
                      </>
                    )}
                    {data && data.unit_code !== null && (
                      <>
                        <Row>
                          <Col lg={4}>
                            <h6>Project Unit In Which You Are Facing Issue</h6>
                          </Col>
                          <Col lg={8}>
                            {data && data.unit_code === null
                              ? "NA"
                              : data.unit_code}
                          </Col>
                        </Row>
                        <hr />
                      </>
                    )}
                    {data && data?.projects?.length > 0 && (
                      <>
                        <Row>
                          <Col lg={4}>
                            <h6>Prefered Projects</h6>
                          </Col>
                          <Col lg={8}>
                            {data &&
                            data?.projects &&
                            data?.projects?.length > 0
                              ? data.projects.map((project, index) => (
                                  <span key={index}>
                                    {index > 0 && ", "}
                                    {project?.project_name}
                                  </span>
                                ))
                              : "NA"}
                          </Col>
                        </Row>
                        <hr />
                      </>
                    )}
                    {data && data.problem_facing_since !== null && (
                      <>
                        <Row>
                          <Col lg={4}>
                            <h6>Facing Maintainence Issue Since</h6>
                          </Col>
                          <Col lg={8}>
                            {data && data.problem_facing_since === null
                              ? "NA"
                              : data.problem_facing_since}
                          </Col>
                        </Row>
                        <hr />
                      </>
                    )}
                    {data &&
                      data.prefered_duration_for_user_engagement !== null && (
                        <>
                          <Row>
                            <Col lg={4}>
                              <h6>
                                How soon you want to start new engagement?
                              </h6>
                            </Col>
                            <Col lg={8}>
                              {data &&
                              data.prefered_duration_for_user_engagement ===
                                null
                                ? "NA"
                                : data.prefered_duration_for_user_engagement}
                            </Col>
                          </Row>
                          <hr />
                        </>
                      )}
                    {data && data.budget_prefered !== null && (
                      <>
                        <Row>
                          <Col lg={4}>
                            <h6>Budget Prefered</h6>
                          </Col>
                          <Col lg={8}>
                            {data && data.budget_prefered === null
                              ? "NA"
                              : data.budget_prefered}
                          </Col>
                        </Row>
                        <hr />
                      </>
                    )}
                    {(data && data.support_ticket_topic !== null) ||
                      ("" && (
                        <>
                          {" "}
                          <Row>
                            <Col lg={4}>
                              <h6>Category</h6>
                            </Col>
                            <Col lg={8}>
                              {data && data.support_ticket_topic === null
                                ? "NA"
                                : data.support_ticket_topic}
                            </Col>
                          </Row>
                          <hr />
                        </>
                      ))}
                    <Row>
                      <Col lg={4}>
                        <h6>Task Description</h6>
                      </Col>
                      <Col lg={8}>{data && data.description}</Col>
                    </Row>
                    <hr></hr>
                    {data && data?.files?.length > 0 && (
                      <Row>
                        <Col lg={4}>
                          <h6>Supporting Pics / Videos / Document</h6>
                        </Col>
                        <Col lg={8}>
                          {data && data.files?.length > 0 ? (
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              {data.files.map((file, index) => (
                                <div
                                  key={index}
                                  style={{
                                    width: "200px",
                                    height: "150px",
                                    margin: "5px",
                                    overflow: "hidden",
                                    borderRadius: "15px",
                                  }}
                                >
                                  <img
                                    src={file.file_path}
                                    alt={`File ${index}`}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          ) : (
                            "NA"
                          )}
                        </Col>
                        <hr></hr>
                        {finalDocument &&
                          data.status === 4 &&
                          finalDocument?.length > 0 && (
                            <Row>
                              <Col lg={4}>
                                <h6>Work Completion Proof</h6>
                              </Col>
                              <Col lg={8}>
                                {finalDocument && finalDocument?.length > 0 && (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {finalDocument?.map((file, index) => (
                                      <div
                                        key={index}
                                        style={{
                                          width: "200px",
                                          height: "150px",
                                          margin: "5px",
                                          overflow: "hidden",
                                          borderRadius: "15px",
                                        }}
                                      >
                                        <img
                                          src={file.file_path}
                                          alt={`File ${index}`}
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                          }}
                                        />
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </Col>
                            </Row>
                          )}
                      </Row>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}

export default ServiceRequestView
