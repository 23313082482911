import axios from "axios"
import { del, get, post, postWithFile, put } from "./api"
import * as url from "./url"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

const login = data => post(url.LOGIN, data)
const logout = data => get(url.LOGOUT_USER, data)
const updatePass = user => post(url.UPDATE_PASS, user)
export const forgotPassword = user => post(url.FORGET_PASSWORD, user)

// CITY,STATES AND COUNTRY
export const getCities = stateId => get(`${url.GET_CITIES}/${stateId}`)
export const getStates = states => get(`${url.GET_STATES}/${states}`)
export const getCountries = () => get(url.GET_COUNTRIES)

//PROJECT UNITS
export const getProjectUnits = data => post(`${url.GET_PROJECTUNITS}`,data)
export const getProjectUnitDetails = id =>
  get(`${url.GET_PROJECTUNIT_DETAIL}/${id}`)
export const addNewProjectUnit = () => post(url.ADD_NEW_PROJECTUNIT)
export const updateProjectUnit = () => post(url.UPDATE_PROJECTUNIT)
export const deleteProjectUnit = () => del(url.DELETE_PROJECTUNIT)

//SERVICE REQUEST
export const getServiceRequests = (data) => post(`${url.GET_SERVICE_REQUESTS}`,data)
export const getPendingRequests = () => get(`${url.GET_PENDING_REQUESTS}`)
export const getServiceRequestType = () =>
  get(`${url.GET_SERVICE_REQUEST_TYPE}`)
export const getServiceRequestDetails = id =>
  get(`${url.GET_SERVICE_REQUEST_DETAIL}/${id}`)
export const addNewServiceRequest = (clientId, serviceRequest) =>
  postWithFile(`${url.ADD_NEW_SERVICE_REQUEST}/${clientId}`, serviceRequest)
export const updateServiceRequest = () => post(url.UPDATE_SERVICE_REQUEST)
export const deleteServiceRequest = id =>
  del(`${url.DELETE_SERVICE_REQUEST}/${id}`)
export const getProjectUnit = (values) => post(`${url.GET_PROJECT_UNIT}`,values)
export const getClientProject = id =>
  get(`${url.GET_CLIENT_PROJECT_FOR_SERVICE_REQUEST}/${id}`)

export const getProjectUnitCategory = () =>
  get(`${url.GET_PROJECT_UNIT_CATEGORY}`)
export const getMaintenanceIssues = () => get(`${url.GET_MAINTENANCE_ISSUES}`)
export const getClientInvoices = id => get(`${url.GET_CLIENT_INVOICES}/${id}`)
export const getSupportTicket = () => get(`${url.GET_SUPPORT_TICKET}`)
export const getAllProjects = () => get(`${url.GET_ALL_PROJECTS}`)

export const getPendingBillList = data =>
  post(`${url.GET_PENDING_BILL_LIST}`,data)
export const getBillHistoryList = data =>
  post(`${url.GET_BILL_HISTORY_LIST}`,data)
export const getBillReceiptList = data =>
  post(`${url.GET_BILL_RECEIPT_LIST}`,data)
export const getReceiptPreview = clientId =>
  get(`${url.GET_RECEIPT_PREVIEW}/${clientId}`)
export const getCreditNoteList = data =>
  post(`${url.GET_CREDIT_NOTE_LIST}`,data)

export const getLatestInvoice = clientId =>
  get(`${url.GET_LATEST_INVOICE}/${clientId}`)
export const getInvoiceData = billingId =>
  get(`${url.GET_INVOICE_DATA}/${billingId}`)
export const getPreviousInvoicesList = data =>
  post(`${url.GET_PREVIOUS_INVOICES_LIST}`,data)
export const getServiceRequest = data =>
  post(`${url.GET_SERVICE_REQUEST}`,data)
//graph for dashboard
export const getGasConsumption = data =>
  post(`${url.GET_GAS_CONSUMPTION}`,data)
export const getDgConsumption = data =>
  post(`${url.GET_DG_CONSUMPTION}`,data)
export const getElectricityConsumption = data =>
  post(`${url.GET_ELECTRICITY_CONSUMPTION}`,data)
export const getWaterConsumption = data =>
  post(`${url.GET_WATER_CONSUMPTION}`,data)

export const getClientDetail = data =>
  get(`${url.GET_CLIENT_DETAIL}/${data.client_id}`,data)
export const getLatestProjectUnit = data =>
  post(`${url.GET_LASTEST_PROJECT_UNIT}`,data)

//client Payment list filter  for billing history
export const getCompanyFilterForHistory = id => get(`${url.GET_COMPANY_FILTER_FOR_HISTORY}/${id}`)
export const getCompanyLocationFilterForHistory = id =>
  get(`${url.GET_COMPANY_LOCATION_FILTER_FOR_HISTORY}/${id}`)
export const getCustomerFilterForHistory = id => get(`${url.GET_CUSTOMER_FILTER_FOR_HISTORY}/${id}`)
export const getProjectFilterForHistory = id => get(`${url.GET_PROJECT_FILTER_FOR_HISTORY}/${id}`)
export const getFinancialYearFilterForHistory = id =>
  get(`${url.GET_FINANCIAL_YEAR_FILTER_FOR_HISTORY}`)


//client Payment list filter for billing pending list 
export const getCompanyFilterForPending = id => get(`${url.GET_COMPANY_FILTER_FOR_PENDING}/${id}`)
export const getCompanyLocationFilterForPending = id =>
  get(`${url.GET_COMPANY_LOCATION_FILTER_FOR_PENDING}/${id}`)
export const getCustomerFilterForPending = id => get(`${url.GET_CUSTOMER_FILTER_FOR_PENDING}/${id}`)
export const getProjectFilterForPending = id => get(`${url.GET_PROJECT_FILTER_FOR_PENDING}/${id}`)
export const getFinancialYearFilterForPending = id =>
get(`${url.GET_FINANCIAL_YEAR_FILTER_FOR_PENDING}`)

//consumption list filter

export const getCustomerFilterForConsumption = id => get(`${url.GET_CUSTOMER_FILTER_FOR_CONSUMPTION}/${id}`)
export const getProjectFilterForConsumption = data => post(`${url.GET_PROJECT_FILTER_FOR_CONSUMPTION}`,data)
export const getUnitForConsumption = value =>
post(`${url.GET_UNIT_FOR_CONSUMPTION}`,value)
export const getConsumptionFilter = value =>
post(`${url.GET_CONSUMPTION_FILTER}`,value)

//dashboard

  export const getDashboardData = data =>
  post(`${url.GET_DASHBOARD_DATA}`,data)

//
export const getProjectInCustomer = (id) => get(`${url.GET_PROJECT_IN_CUSTOMER}/${id}`)


export { getLoggedInUser, isUserAuthenticated, login, logout, updatePass }
