import React, { useEffect, useState } from "react"
import { Col, Button, Row, Input, Label } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import "flatpickr/dist/themes/material_blue.css"
import FlatPickr from "react-flatpickr"
import { Link } from "react-router-dom"
import {
  getCompanyFilter,
  getCustomerFilter,
  getProjectFilter,
} from "store/bills&payment/paymentFilterForHistory/actions"
import {
  getBillHistoryList,
  getConsumptionFilter,
  getCustomerFilterForConsumption,
  getProjectFilterForConsumption,
  getUnitForConsumption,
} from "store/actions"
// import { getBillingRegisterList, getCompanyFilter, getCompanyLocationFilter, getCustomerFilter, getFinancialYearFilter, getProjectFilter } from "store/actions"

const JobListGlobalFilter = ({ setGlobalFilter }) => {
  // const billingRegisterState = state => state.billing.billingRegister.data
  // const billingRegisterList = useSelector(billingRegisterState) || []
  // const billingCompanyState = state => state.consumptionReport.companyFilter.company
  const UnitState = state => state.consumptionReport.unit.project_units
  const projectstate = state => state.consumptionReport.projectFilter.data
  const consumptionListState = state => state.consumptionReport.Consumption.data
  const consumptionList = useSelector(consumptionListState) || []
  const unitList = useSelector(UnitState) || []

  // const billingCompanyList = useSelector(billingCompanyState) || []
  const projectList = useSelector(projectstate) || []

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [consumption, setConsumption] = useState("")
  const [project, setProject] = useState("")
  const [company, setCompany] = useState("")
  const [month, setMonth] = useState("")
  const [year, setYear] = useState("")
  const [unit, setUnit] = useState("")
  const dispatch = useDispatch()

  const handleSelectConsumptionType = ele => {
    const value = ele.value
    setConsumption(value)
  }

  const handleSelectProject = ele => {
    const value = ele.value
    setProject(value)
  }
  const handleSelectCompany = ele => {
    const value = ele.value
    setCompany(value)
  }

  const handleSelectUnit = ele => {
    const value = ele.value
    setUnit(value)
  }
  const handleSelectMonth = ele => {
    const value = ele.value
    setMonth(value)
  }
  const handleSelectYear = ele => {
    const value = ele.value
    setYear(value)
  }

  const handleSearch = () => {
    const value = {
      consumption_type: consumption,
      project_id: project,
      client_project_unit_id: unit,
      client_id: user.id,
      user_type: user.user_type,
      month: month,
      year: year,
    }
    dispatch(getConsumptionFilter(value))
  }

  useEffect(() => {
    if (user) {
      dispatch(
        getProjectFilterForConsumption({
          client_id: user.id,
          user_type: user.user_type,
        })
      )
    }
  }, [user])
  useEffect(() => {
    if (project !== "") {
      const value = {
        project_id: project,
        client_id: user.id,
      }
      dispatch(getUnitForConsumption(value))
    } else if (project === "") {
      setUnit("")
    }
  }, [project])

  console.log("unit", unit)
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const years = []
  const startYear = 2022
  const endYear = new Date().getFullYear()

  // Create an array of years from startYear to endYear
  for (let year = startYear; year <= endYear; year++) {
    years.push(year)
  }

  // Sort the array in descending order
  years.sort((a, b) => b - a)

  // Move the current year to the top of the sorted array
  const currentYearIndex = years.indexOf(new Date().getFullYear())
  if (currentYearIndex !== -1) {
    const currentYear = years.splice(currentYearIndex, 1)[0]
    years.unshift(currentYear)
  }

  useEffect(() => {
    const date = new Date()
    const month = date.getMonth() + 1 // getMonth() returns 0-11, so add 1
    setMonth(month)
  }, [])

  useEffect(() => {
    const date = new Date()
    const year = date.getFullYear()
    setYear(year)
  }, [])

  return (
    <React.Fragment>
      <Col xxl={2} lg={1}>
        <select
          className="form-control select2 mb-3 mb-xxl-0"
          defaultValue="Consumption Type"
          onChange={e => handleSelectConsumptionType(e.target)}
        >
          <option disabled>Consumption Type</option>
          <option value="Water">Water</option>
          <option value="Electricity">Electricity</option>
          <option value="Gas">Gas</option>
          <option value="DG">DG</option>
        </select>
      </Col>
      <Col xxl={2} lg={2}>
        <select
          className="form-control select2 mb-3 mb-xxl-0"
          defaultValue="Select Project"
          onChange={e => handleSelectProject(e.target)}
        >
          <option value="">Select Project</option>
          {(projectList || []).map(item => (
            <option key={item.id} value={item.id}>
              {item.project_name}
            </option>
          ))}
        </select>
      </Col>

      <Col xxl={1} lg={2}>
        <select
          className="form-control select2 mb-3 mb-xxl-0"
          defaultValue="Select Unit"
          onChange={e => handleSelectUnit(e.target)}
          value={unit}
        >
          <option value="">Select Unit</option>
          {(unitList || []).map(item => (
            <option key={item.id} value={item.id}>
              {item.unit_code}
            </option>
          ))}
        </select>
      </Col>
      <Col xxl={2} lg={4}>
        <div className="mb-2" id="datepicker1">
          <Input
            type="select"
            id="Month"
            name="Month"
            onChange={e => handleSelectMonth(e.target)}
            value={month}
            className="form-control select2 mb-3 mb-xxl-0"
          >
            <option value="">Select Month</option>
            {months.map((month, index) => (
              <option key={index + 1} value={index + 1}>
                {month}
              </option>
            ))}
          </Input>
        </div>
      </Col>

      <Col xxl={2} lg={4}>
        <div className="mb-2" id="datepicker2">
          <Input
            type="select"
            id="Year"
            name="Year"
            value={year}
            onChange={e => handleSelectYear(e.target)}
          >
            <option value="">Select Year</option>
            {years.map(year => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Input>
        </div>
      </Col>

      <Col xxl={1} lg={1} className="mb-3">
        <div className="mb-3 mb-xxl-0">
          <button
            type="button"
            className="btn btn-soft-secondary w-100"
            onClick={handleSearch}
          >
            <i className="mdi mdi-filter-outline align-middle"></i> Filter
          </button>
        </div>
      </Col>
    </React.Fragment>
  )
}

export default JobListGlobalFilter
