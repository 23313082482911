import React from "react"
import { Navigate } from "react-router-dom"

import Login from "../VIP PAGES/Authentication/Login"
import ChangePass from "../VIP PAGES/Authentication/changePassword"
import Dashboard from "../VIP PAGES/Dashboard/index"
import CustomerProfilePreview from "VIP PAGES/CustomerProfilePreview/customerProfilePreview"
import Logout from "../VIP PAGES/Authentication/Logout"
import MyProfile from "VIP PAGES/MyProfile/myProfile"
import Settings from "VIP PAGES/Settings/settings"
import PendingBillList from "VIP PAGES/Billings&Payments/PendingBills/pendingBillList"
import BillHistoryList from "VIP PAGES/Billings&Payments/BillingHistory/billHistoryList"
import MyProjectUnitsList from "VIP PAGES/MyProjectUnits/myProjectUnitsList"
import ProjectUnitView from "VIP PAGES/MyProjectUnits/projectUnitView"
import Helpdesk from "VIP PAGES/Helpdesk/helpdesk"
import AllRequestsList from "VIP PAGES/ServiceRequests/AllRequests/allRequestsList"
import PendingRequestsList from "VIP PAGES/ServiceRequests/PendingRequests/pendingRequestsList"
import ServiceRequestView from "VIP PAGES/ServiceRequests/ViewServiceRequest/viewServiceRequest"
import AddServiceRequest from "VIP PAGES/ServiceRequests/AddServiceRequest/addServiceRequest"
import ForgetPassword from "VIP PAGES/Authentication/ForgetPassword"
import BillReceiptList from "VIP PAGES/Billings&Payments/BillingReceipt/billReceiptList"
import ConsumptionReportList from "VIP PAGES/Consumption/consumptionReportList"
import CreditHistoryList from "VIP PAGES/Billings&Payments/CreditNote/creditHistoryList"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/bills-payments/pending-bills", component: <PendingBillList /> },
  { path: "/bills-payments/bill-history", component: <BillHistoryList /> },
  { path: "/bills-payments/bill-receipt", component: <BillReceiptList /> },
  { path: "/bills-payments/Credit-note-history", component: <CreditHistoryList/> },
  { path: "/project-units", component: <MyProjectUnitsList /> },
  { path: "/project-units/project-unit-view/:id", component: <ProjectUnitView /> },
  { path: "/helpdesk", component: <Helpdesk /> },
  { path: "/service-requests/all-service-requests", component: <AllRequestsList /> },
  { path: "/service-requests/pending-service-requests", component: <PendingRequestsList /> },
  { path: "/service-requests/view-service-requests/:id", component: <ServiceRequestView /> },
  { path: "/service-requests/add-new-service-request", component: <AddServiceRequest /> },
  { path: "/consumption-report", component: <ConsumptionReportList /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
    },
]

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forget-password", component: <ForgetPassword /> },
  { path: "/change-password", component: <ChangePass /> },
  { path: "/customer-profile-preview", component: <CustomerProfilePreview /> },
  { path: "/myProfile", component: <MyProfile /> },
  { path: "/settings", component: <Settings /> },
]

export { authProtectedRoutes, publicRoutes }
