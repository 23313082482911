import {
  GET_SERVICE_REQUESTS_FAIL,
  GET_SERVICE_REQUESTS_SUCCESS,
  GET_SERVICE_REQUEST_DETAIL_FAIL,
  GET_SERVICE_REQUEST_DETAIL_SUCCESS,
  ADD_SERVICE_REQUEST_SUCCESS,
  ADD_SERVICE_REQUEST_FAIL,
  UPDATE_SERVICE_REQUEST_SUCCESS,
  UPDATE_SERVICE_REQUEST_FAIL,
  DELETE_SERVICE_REQUEST_SUCCESS,
  DELETE_SERVICE_REQUEST_FAIL,
  GET_PROJECT_UNIT_CATEGORY_SUCCESS,
  GET_PROJECT_UNIT_CATEGORY_FAIL,
  GET_MAINTENANCE_ISSUES_SUCCESS,
  GET_MAINTENANCE_ISSUES_FAIL,
  GET_CLIENT_INVOICES_SUCCESS,
  GET_CLIENT_INVOICES_FAIL,
  GET_SUPPORT_TICKET_SUCCESS,
  GET_SUPPORT_TICKET_FAIL,
  GET_SERVICE_REQUEST_TYPE_FAIL,
  GET_SERVICE_REQUEST_TYPE_SUCCESS,
  REMOVE_PREVIOUS_RESPONSE,
  GET_PENDING_REQUESTS_SUCCESS,
  GET_PENDING_REQUESTS_FAIL,
  GET_ALL_PROJECTS_SUCCESS,
  GET_ALL_PROJECTS_FAIL,
  GET_PROJECT_UNIT_SUCCESS,
  GET_PROJECT_UNIT_FAIL,
  GET_CLIENT_PROJECT_FOR_SERVICE_REQUEST_SUCCESS,
  GET_CLIENT_PROJECT_FOR_SERVICE_REQUEST_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  serviceRequests: [],
  pendingRequests: [],
  addServiceRequest: [],
  serviceRequestType: [],
  projectUnits: [],
  maintenanceIssues: [],
  clientInvoices: [],
  supportTickets: [],
  allProjects: [],
  clientProjects: [],
  projectUnit: [],
  serviceRequestDetail: {},
  error: {},
  loading: true,
}

const serviceRequests = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SERVICE_REQUESTS_SUCCESS:
      return {
        ...state,
        serviceRequests: action.payload,
        loading: false,
      }

    case GET_SERVICE_REQUESTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SERVICE_REQUEST_TYPE_SUCCESS:
      return {
        ...state,
        serviceRequestType: action.payload,
        loading: false,
      }

    case GET_SERVICE_REQUEST_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_SERVICE_REQUEST_SUCCESS:
      return {
        ...state,
        addServiceRequest: [...state.addServiceRequest, action.payload],
      }

    case ADD_SERVICE_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SERVICE_REQUEST_DETAIL_SUCCESS:
      return {
        ...state,
        serviceRequestDetail: action.payload,
      }

    case UPDATE_SERVICE_REQUEST_SUCCESS:
      return {
        ...state,
        serviceRequests: state.serviceRequests.map(serviceRequest =>
          serviceRequest.id.toString() === action.payload.id.toString()
            ? { serviceRequest, ...action.payload }
            : serviceRequest
        ),
      }

    case UPDATE_SERVICE_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_SERVICE_REQUEST_SUCCESS:
      return {
        ...state,
        serviceRequests: state.serviceRequests.filter(
          serviceRequest =>
            serviceRequest.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_SERVICE_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SERVICE_REQUEST_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    //PROJECT UNIT CATEGORY
    case GET_PROJECT_UNIT_CATEGORY_SUCCESS:
      return {
        ...state,
        projectUnits: action.payload,
        loading: false,
      }

    case GET_PROJECT_UNIT_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    //MAINTENANCE ISSUES
    case GET_MAINTENANCE_ISSUES_SUCCESS:
      return {
        ...state,
        maintenanceIssues: action.payload,
        loading: false,
      }

    case GET_MAINTENANCE_ISSUES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    //CLIENT INVOICES
    case GET_CLIENT_INVOICES_SUCCESS:
      return {
        ...state,
        clientInvoices: action.payload,
        loading: false,
      }

    case GET_CLIENT_INVOICES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    //SUPPORT TICKET
    case GET_SUPPORT_TICKET_SUCCESS:
      return {
        ...state,
        supportTickets: action.payload,
        loading: false,
      }

    case GET_SUPPORT_TICKET_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case REMOVE_PREVIOUS_RESPONSE:
      return { ...state, addServiceRequest: null }

    case GET_PENDING_REQUESTS_SUCCESS:
      return {
        ...state,
        pendingRequests: action.payload,
        loading: false,
      }

    case GET_PENDING_REQUESTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    //GET ALL PROJECTS
    case GET_ALL_PROJECTS_SUCCESS:
      return {
        ...state,
        allProjects: action.payload,
        loading: false,
      }

    case GET_ALL_PROJECTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    //GET PROJECT UNIT

    case GET_PROJECT_UNIT_SUCCESS:
      return {
        ...state,
        projectUnit: action.payload,
        loading: false,
      }

    case GET_PROJECT_UNIT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // CLIENT PROJECT

    case GET_CLIENT_PROJECT_FOR_SERVICE_REQUEST_SUCCESS:
      return {
        ...state,
        clientProjects: action.payload,
        loading: false,
      }

    case GET_CLIENT_PROJECT_FOR_SERVICE_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default serviceRequests
