import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import * as Yup from "yup"
import { useFormik } from "formik"
import PropTypes from "prop-types"
import Select from "react-select"

import {
  FormFeedback,
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Form,
  Input,
} from "reactstrap"

import { Link, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import {
  addNewServiceRequest,
  getAllProjects,
  getClientInvoices,
  getClientProject,
  getClientProjectUnit,
  getMaintenanceIssues,
  getProjectUnitCategory,
  getServiceRequestType,
  getSupportTickets,
} from "store/actions"
import { createSelector } from "reselect"
import withRouter from "components/Common/withRouter"

const AddServiceRequest = props => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [selectedFiles, setselectedFiles] = useState([])

  const [clientId, setSetClientId] = useState(user?.id)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const serviceRequestState = state => state.serviceRequests
  const AllRequestProperties = createSelector(
    serviceRequestState,

    serviceRequests => ({
      serviceRequestType:
        serviceRequests.serviceRequestType.service_request_types,
      loading: serviceRequests.loading,
      maintenanceIssues: serviceRequests.maintenanceIssues.maintenance_issues,
      clientInvoices: serviceRequests.clientInvoices.data,
      projectUnits: serviceRequests.projectUnits.project_unit_category,
      supportTickets: serviceRequests.supportTickets.customer_support_ticket,
      allProjects: serviceRequests.allProjects.data,
      projectUnit: serviceRequests.projectUnit.data,
      clientProject: serviceRequests.clientProjects.projects,
    })
  )

  const {
    serviceRequestType,
    loading,
    maintenanceIssues,
    clientInvoices,
    projectUnits,
    supportTickets,
    allProjects,
    projectUnit,
    clientProject,
  } = useSelector(AllRequestProperties)

  console.log(projectUnits)

  useEffect(() => {
    dispatch(getServiceRequestType())
    dispatch(getMaintenanceIssues())
    dispatch(getProjectUnitCategory())
    dispatch(getSupportTickets())
    dispatch(getAllProjects())
  }, [dispatch])

  //FORMIK VALIDATION
  const validation = useFormik({
    // enableReinitialize: true,

    initialValues: {
      type_of_service: "",
      // engagement_type: "",
      issue: "",
      bills: "",
      support_ticket_topic_id: "",
      type_of_service: "",
      project_unit_category: "",
      maintenance_issues: "",
      client_project_unit_id: "",
      description: "",
      project_id: "",

      prefered_project_id: "",
      prefered_duration_for_user_engagement: "",
      budget_prefered: "",
      problem_facing_since: "",
      documents: selectedFiles,
    },
    validationSchema: Yup.object().shape({
      type_of_service: Yup.number(),
      prefered_duration_for_user_engagement: Yup.string(),
      description: Yup.string().max(2000),
    }),

    onSubmit: async values => {
      dispatch(addNewServiceRequest(clientId, values, props.router.navigate))
    },
  })

  useEffect(() => {
    dispatch(getClientInvoices(clientId))
    dispatch(getClientProject(clientId))
  }, [clientId])

  useEffect(() => {
    if (clientId && validation.values.project_id) {
      const data = {
        client_id: clientId,
        project_id: validation.values.project_id,
      }
      dispatch(getClientProjectUnit(data))
    }
  }, [clientId, validation.values.project_id])

  //File Upload

  function handleAcceptedFiles(files) {
    const updatedFiles = files.map(file => ({
      uploaded_by: "Customer", // You may need to change this value accordingly
      uploaded_by_id: user?.id, // Assuming user is accessible
      files: file,
      name: file.name, // Add the name of the file
      preview: URL.createObjectURL(file),
      formattedSize: formatBytes(file.size),
    }))
    setselectedFiles(updatedFiles)
  }

  useEffect(() => {
    validation.setFieldValue("documents", selectedFiles)
  }, [selectedFiles])
  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const handleChange = event => {
    validation.setFieldValue(
      "prefered_duration_for_user_engagement",
      event.target.value
    )
  }

  const options = (allProjects || []).map(item => ({
    value: item.id,
    label: item.project_name,
  }))
  return (
    <React.Fragment>
      <div className="page-content ">
        <Container fluid={true}>
          <Row className="justify-content-center">
            <Col lg={8}>
              <CardTitle className="h4 mb-3" style={{ fontSize: "20px" }}>
                <div className="d-flex gap-2 ">
                  <i
                    className="mdi mdi-arrow-left font-size-20"
                    style={{ color: "grey" }}
                    onClick={() => {
                      navigate(-1)
                    }}
                  ></i>
                  Create New Service Request
                </div>
              </CardTitle>
              <Card>
                <CardBody>
                  <Form
                    autoComplete="off"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col>
                        <Row className="mb-3">
                          <Col lg={12}>
                            <Label className="form-label">
                              Type of Service*
                            </Label>
                            <select
                              name="type_of_service"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.type_of_service}
                              className={`form-select ${
                                validation.touched.type_of_service &&
                                validation.errors.type_of_service
                                  ? "is-invalid"
                                  : ""
                              }`}
                            >
                              {!validation.values.type_of_service && (
                                <option>Select Service Type</option>
                              )}
                              {(serviceRequestType || []).map((data, index) => (
                                <option key={index} value={data.id}>
                                  {data.service_type}
                                </option>
                              ))}
                            </select>
                            {validation.touched.type_of_service &&
                            validation.errors.type_of_service ? (
                              <FormFeedback type="invalid">
                                {validation.errors.type_of_service}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                        {validation.values.type_of_service === "1" && (
                          <>
                            <Row className="mb-3">
                              <Col lg={12}>
                                <Label className="form-label">
                                  Project Unit Category*
                                </Label>
                                <select
                                  name="project_unit_category"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.project_unit_category
                                  }
                                  className={`form-select ${
                                    validation.touched.project_unit_category &&
                                    validation.errors.project_unit_category
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                >
                                  {!validation.values.project_unit_category && (
                                    <option>
                                      Select Project Unit Category
                                    </option>
                                  )}
                                  {(projectUnits || []).map((data, index) => (
                                    <option key={index} value={data.id}>
                                      {data.category_title}
                                    </option>
                                  ))}
                                </select>
                                {validation.touched.project_unit_category &&
                                validation.errors.project_unit_category ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.project_unit_category}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col lg={12}>
                                <Label className="form-label">
                                  Prefered Project*
                                </Label>
                                <select
                                  name="prefered_project_id"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.prefered_project_id}
                                  className={`form-select ${
                                    validation.touched.prefered_project_id &&
                                    validation.errors.prefered_project_id
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                >
                                  {!validation.values.prefered_project_id && (
                                    <option>Select Prefered Project</option>
                                  )}
                                  {(allProjects || []).map((data, index) => (
                                    <option key={index} value={data.id}>
                                      {data.project_name}
                                    </option>
                                  ))}
                                </select>
                                {validation.touched.prefered_project_id &&
                                validation.errors.prefered_project_id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.prefered_project_id}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                              {/* <Col lg={12}>
                                <div className="mb-3">
                                  <Label className="control-label">
                                    Prefered Project*
                                  </Label>
                                  <Select
                                    // classNamePrefix="select2-selection"
                                    placeholder="Please Select Prefered Project"
                                    options={options}
                                    isMulti={true}
                                    id="prefered_project_id"
                                    name="prefered_project_id"
                                    onChange={selectedOptions => {
                                      const selectedValues =
                                        selectedOptions.map(
                                          option => option.value
                                        )
                                      validation.setFieldValue(
                                        "prefered_project_id",
                                        selectedValues.join(",")
                                      )
                                    }}
                                    value={options.filter(option =>
                                      (
                                        (validation.values
                                          .prefered_project_id || "") + ""
                                      )
                                        .split(",")
                                        .includes(option.value.toString())
                                    )}
                                    onBlur={validation.handleBlur}
                                    className={`${
                                      validation.touched.prefered_project_id &&
                                      validation.errors.prefered_project_id
                                        ? " form-control is-invalid "
                                        : ""
                                    }`}
                                  />
                                  {validation.touched.prefered_project_id &&
                                    validation.errors.prefered_project_id && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.prefered_project_id}
                                      </FormFeedback>
                                    )}
                                </div>
                              </Col> */}
                            </Row>
                            <Row className="mb-3">
                              <Col lg={12}>
                                <Label className="form-label">
                                  Budget Upto*
                                </Label>
                                <Input
                                  name="budget_prefered"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.budget_prefered}
                                  className={`form-control ${
                                    validation.touched.budget_prefered &&
                                    validation.errors.budget_prefered
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  placeholder="Enter Maximum budget"
                                ></Input>
                                {validation.touched.budget_prefered &&
                                validation.errors.budget_prefered ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.budget_prefered}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col lg={12}>
                                <Label className="form-label">
                                  Requirement*
                                </Label>
                                <textarea
                                  name="description"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.description}
                                  className={`form-control ${
                                    validation.touched.description &&
                                    validation.errors.description
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  placeholder="Brief Your Requirement"
                                ></textarea>
                                {validation.touched.description &&
                                validation.errors.description ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.description}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col lg={12}>
                                <Label className="form-radio">
                                  How soon you want to start new engagement?*
                                </Label>
                                <Col lg="6">
                                  <div className="form-check">
                                    <Input
                                      type="radio"
                                      id="radio1"
                                      name="prefered_duration_for_user_engagement"
                                      value="Immediately"
                                      onClick={e => {
                                        handleChange(e)
                                      }}
                                    />
                                    <Label
                                      className="form-check-label"
                                      htmlFor="radio1"
                                      style={{
                                        paddingLeft: "20px",
                                        paddingRight: "20px",
                                      }}
                                    >
                                      <h6>Immediately</h6>
                                    </Label>
                                  </div>
                                  <div className="form-check">
                                    <Input
                                      type="radio"
                                      id="radio2"
                                      name="prefered_duration_for_user_engagement"
                                      value="With-in a Week"
                                      onClick={e => {
                                        handleChange(e)
                                      }}
                                    />
                                    <Label
                                      className="form-check-label"
                                      htmlFor="radio2"
                                      style={{
                                        paddingLeft: "20px",
                                        paddingRight: "20px",
                                      }}
                                    >
                                      <h6>With-in a Week</h6>
                                    </Label>
                                  </div>
                                  <div className="form-check">
                                    <Input
                                      type="radio"
                                      id="radio3"
                                      name="prefered_duration_for_user_engagement"
                                      value="With-in a Month"
                                      onClick={e => {
                                        handleChange(e)
                                      }}
                                    />
                                    <Label
                                      className="form-check-label"
                                      htmlFor="radio3"
                                      style={{
                                        paddingLeft: "20px",
                                        paddingRight: "20px",
                                      }}
                                    >
                                      <h6>With-in a Month</h6>
                                    </Label>
                                  </div>
                                  <div className="form-check">
                                    <Input
                                      type="radio"
                                      id="radio4"
                                      name="prefered_duration_for_user_engagement"
                                      value="In this Quarter"
                                      onClick={e => {
                                        handleChange(e)
                                      }}
                                    />
                                    <Label
                                      className="form-check-label"
                                      htmlFor="radio4"
                                      style={{
                                        paddingLeft: "20px",
                                        paddingRight: "20px",
                                      }}
                                    >
                                      <h6>In this Quarter</h6>
                                    </Label>
                                  </div>
                                  <div className="form-check">
                                    <Input
                                      type="radio"
                                      id="radio5"
                                      name="prefered_duration_for_user_engagement"
                                      value="Still in a Planning phase"
                                      onClick={e => {
                                        handleChange(e)
                                      }}
                                    />
                                    <Label
                                      className="form-check-label"
                                      htmlFor="planning_phase"
                                      style={{
                                        paddingLeft: "20px",
                                        paddingRight: "20px",
                                      }}
                                    >
                                      <h6>Still in a Planning phase</h6>
                                    </Label>
                                  </div>
                                </Col>
                              </Col>
                            </Row>
                          </>
                        )}
                        {validation.values.type_of_service === "2" && (
                          <>
                            <Row className="mb-3">
                              <Col lg={12}>
                                <Label className="form-label">
                                  Type of Maintenance*
                                </Label>
                                <select
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  name="maintenance_issues"
                                  value={validation.values.maintenance_issues}
                                  className={`form-select ${
                                    validation.touched.maintenance_issues &&
                                    validation.errors.maintenance_issues
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                >
                                  {!validation.values.project_unit_category && (
                                    <option>Select Maintenance Issue</option>
                                  )}
                                  {(maintenanceIssues || []).map(
                                    (data, index) => (
                                      <option key={index} value={data.id}>
                                        {data.issue_name}
                                      </option>
                                    )
                                  )}
                                </select>
                                {validation.touched.maintenance_issues &&
                                validation.errors.maintenance_issues ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.maintenance_issues}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col lg={12}>
                                <Label className="form-label">
                                  Select Project In Which You Are Facing Issue*
                                </Label>
                                <select
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  name="project_id"
                                  value={validation.values.project_id}
                                  className={`form-select ${
                                    validation.touched.project_id &&
                                    validation.errors.project_id
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                >
                                  {!validation.values.project_id && (
                                    <option>Select Project</option>
                                  )}
                                  {(clientProject || []).map((data, index) => (
                                    <option key={index} value={data.id}>
                                      {data.project_name}
                                    </option>
                                  ))}
                                </select>
                                {validation.touched.project_id &&
                                validation.errors.project_id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.project_id}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col lg={12}>
                                <Label className="form-label">
                                  Select Project Unit In Which You Are Facing
                                  Issue*
                                </Label>
                                <select
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  name="client_project_unit_id"
                                  value={
                                    validation.values.client_project_unit_id
                                  }
                                  className={`form-select ${
                                    validation.touched.client_project_unit_id &&
                                    validation.errors.client_project_unit_id
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                >
                                  {!validation.values
                                    .client_project_unit_id && (
                                    <option>Select Project Unit</option>
                                  )}
                                  {(projectUnit || []).map((data, index) => (
                                    <option key={index} value={data.id}>
                                      {data.unit_code}
                                    </option>
                                  ))}
                                </select>
                                {validation.touched.client_project_unit_id &&
                                validation.errors.client_project_unit_id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.client_project_unit_id}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col lg={12}>
                                <Label className="form-label">Issue*</Label>
                                <textarea
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  name="description"
                                  value={validation.values.description}
                                  className={`form-control ${
                                    validation.touched.description &&
                                    validation.errors.description
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  placeholder="Describe your Issue in Detail"
                                ></textarea>
                                {validation.touched.description &&
                                validation.errors.description ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.description}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col lg={3}>
                                <Label className="form-label">
                                  Facing Issue Since*
                                </Label>
                                <Input
                                  type="date"
                                  name="problem_facing_since"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.problem_facing_since}
                                  className={`form-control ${
                                    validation.touched.problem_facing_since &&
                                    validation.errors.problem_facing_since
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                              </Col>
                            </Row>
                            <Dropzone
                              onDrop={acceptedFiles => {
                                handleAcceptedFiles(acceptedFiles)
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="dropzone">
                                  <div
                                    className="dz-message needsclick mt-2"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div className="mb-3">
                                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                                    </div>
                                    <h4>Drop files here or click to upload.</h4>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                            <div
                              className="dropzone-previews mt-3 mb-3"
                              id="file-previews"
                            >
                              {selectedFiles.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                          />
                                        </Col>
                                        <Col>
                                          <Link
                                            to="#"
                                            className="text-muted font-weight-bold"
                                          >
                                            {f.name}
                                          </Link>
                                          <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                )
                              })}
                            </div>
                          </>
                        )}
                        {validation.values.type_of_service === "3" && (
                          <>
                            <Row className="mb-3">
                              <Col lg={12}>
                                <Label className="form-label">Bills*</Label>
                                <select
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  className="form-select"
                                  name="bills"
                                >
                                  {!validation.values.bills && (
                                    <option>Select Bill</option>
                                  )}
                                  {(clientInvoices || []).map((data, index) => (
                                    <option key={index} value={data.bill_no}>
                                      {data.bill_no}
                                    </option>
                                  ))}
                                </select>
                                {validation.touched.bills &&
                                validation.errors.bills ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.bills}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col lg={12}>
                                <Label className="form-label">Issue*</Label>
                                <textarea
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  name="description"
                                  value={validation.values.description}
                                  className={`form-control ${
                                    validation.touched.description &&
                                    validation.errors.description
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  placeholder="Describe your Issue in Detail"
                                ></textarea>
                                {validation.touched.description &&
                                validation.errors.description ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.description}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </Row>
                          </>
                        )}
                        {validation.values.type_of_service === "4" && (
                          <>
                            <Row className="mb-3">
                              <Col lg={12}>
                                <Label className="form-label">
                                  Select Project In Which You Are Facing Issue*
                                </Label>
                                <select
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  name="project_id"
                                  value={validation.values.project_id}
                                  className={`form-select ${
                                    validation.touched.project_id &&
                                    validation.errors.project_id
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                >
                                  {!validation.values.project_id && (
                                    <option>Select Project</option>
                                  )}
                                  {(clientProject || []).map((data, index) => (
                                    <option key={index} value={data.id}>
                                      {data.project_name}
                                    </option>
                                  ))}
                                </select>
                                {validation.touched.project_id &&
                                validation.errors.project_id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.project_id}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col lg={12}>
                                <Label className="form-label">
                                  Select Project Unit In Which You Are Facing
                                  Issue*
                                </Label>
                                <select
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  name="client_project_unit_id"
                                  value={
                                    validation.values.client_project_unit_id
                                  }
                                  className={`form-select ${
                                    validation.touched.client_project_unit_id &&
                                    validation.errors.client_project_unit_id
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                >
                                  {!validation.values
                                    .client_project_unit_id && (
                                    <option>Select Project Unit</option>
                                  )}
                                  {(projectUnit || []).map((data, index) => (
                                    <option key={index} value={data.id}>
                                      {data.unit_code}
                                    </option>
                                  ))}
                                </select>
                                {validation.touched.client_project_unit_id &&
                                validation.errors.client_project_unit_id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.client_project_unit_id}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col lg={12}>
                                <Label className="form-label">Category*</Label>
                                <select
                                  name="support_ticket_topic_id"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.support_ticket_topic_id
                                  }
                                  className={`form-select ${
                                    validation.touched
                                      .support_ticket_topic_id &&
                                    validation.errors.support_ticket_topic_id
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                >
                                  {!validation.values
                                    .support_ticket_topic_id && (
                                    <option>
                                      Select customer ticket topic
                                    </option>
                                  )}
                                  {(supportTickets || []).map((data, index) => (
                                    <option key={index} value={data.id}>
                                      {data.ticket_topic_name}
                                    </option>
                                  ))}
                                </select>
                                {validation.touched.support_ticket_topic_id &&
                                validation.errors.support_ticket_topic_id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.support_ticket_topic_id}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col lg={12}>
                                <Label className="form-label">Issue*</Label>
                                <textarea
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  name="description"
                                  value={validation.values.description}
                                  className={`form-control ${
                                    validation.touched.description &&
                                    validation.errors.description
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  placeholder="Describe your Issue in Detail"
                                ></textarea>
                                {validation.touched.description &&
                                validation.errors.description ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.description}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </Row>
                            <Dropzone
                              onDrop={acceptedFiles => {
                                handleAcceptedFiles(acceptedFiles)
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="dropzone">
                                  <div
                                    className="dz-message needsclick mt-2"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div className="mb-3">
                                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                                    </div>
                                    <h4>Drop files here or click to upload.</h4>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                            <div
                              className="dropzone-previews mt-3 mb-3"
                              id="file-previews"
                            >
                              {selectedFiles.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                          />
                                        </Col>
                                        <Col>
                                          <Link
                                            to="#"
                                            className="text-muted font-weight-bold"
                                          >
                                            {f.name}
                                          </Link>
                                          <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                )
                              })}
                            </div>
                          </>
                        )}
                        <Row className="mb-3">
                          <Col className="text-end">
                            <button
                              type="submit"
                              className="btn btn-primary w-lg"
                            >
                              Submit
                            </button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(AddServiceRequest)

AddServiceRequest.propTypes = {
  history: PropTypes.object,
}
