import React, { useEffect, useState } from "react"
import { Col, Button, Row } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import "flatpickr/dist/themes/material_blue.css"
import FlatPickr from "react-flatpickr"
import { Link } from "react-router-dom"
import { getCompanyFilter, getCustomerFilter, getProjectFilter } from "store/bills&payment/paymentFilterForHistory/actions"
import { getBillHistoryList, getPendingBillList } from "store/actions"
// import { getBillingRegisterList, getCompanyFilter, getCompanyLocationFilter, getCustomerFilter, getFinancialYearFilter, getProjectFilter } from "store/actions"

const JobListGlobalFilter = ({ setGlobalFilter }) => {
  // const billingRegisterState = state => state.billing.billingRegister.data
  // const billingRegisterList = useSelector(billingRegisterState) || []
  const billingCompanyState = state => state.vendorPayment.companyFilter.company
  const CustomerState = state => state.vendorPayment.customerFilter.client_project_units
  const projectstate = state => state.vendorPayment.projectFilter.projects
  
  const billingCompanyList = useSelector(billingCompanyState) || []
  const customerList = useSelector(CustomerState) || []
  const projectList = useSelector(projectstate) || []
 
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [status, setStatus] = useState("")
  const [project, setProject] = useState("")
  const [company, setCompany] = useState("")
  const [companyLocation, setCompanyLocation] = useState("")
  const [customer, setCustomer] = useState("")
  const [financialYear, setFinancialYear] = useState("")
  const dispatch = useDispatch()

  const handleSelectStatus = ele => {
    const value = ele.value
    setStatus(value)
  }

  const handleSelectProject = ele => {
    const value = ele.value
    setProject(value)
  }
  const handleSelectCompany = ele => {
    const value = ele.value
    setCompany(value)
  }
  const handleSelectCompanyLocation = ele => {
    const value = ele.value
    setCompanyLocation(value)
  }

  const handleSelectCustomer = ele => {
    const value = ele.value
    setCustomer(value)
  }

  const handleFinancialYear = ele => {
    const value = ele.value
    setFinancialYear(value)
  }
  const handleSearch = () => {
    const value = {
      // status: status,
      company_id: company,
      project_id: project,
      client_project_unit_id: customer,
      user_id: user.id,
      user_type:user.user_type
    }
    dispatch(getPendingBillList(value))
  }

  useEffect(()=>{
    if(user){

    dispatch(getProjectFilter(user.id))
    }
  },[user])
  useEffect(()=>{
    if(project){
    dispatch(getCompanyFilter(project))
    dispatch(getCustomerFilter(project)) 
    }
  },[project])

  return (
    <React.Fragment>
      {/* <Col xxl={1} lg={1}>
        <select
          className="form-control select2 mb-3 mb-xxl-0"
          defaultValue="Status"
          onChange={e => handleSelectStatus(e.target)}
        >
          <option disabled>Status</option>
          <option value="">All</option>
          <option value="1">Paid</option>
          <option value="0">Unpaid</option>
        </select>
      </Col> */}
      <Col xxl={2} lg={2}>
        <select
          className="form-control select2 mb-3 mb-xxl-0"
          defaultValue="Select Project"
          onChange={e => handleSelectProject(e.target)}
        >
          <option value="">Select Project</option>
          {(projectList || []).map(item => (
            <option key={item.id} value={item.id}>
              {item.project_name}
            </option>
          ))}
        </select>
      </Col>
      <Col xxl={2} lg={2}>
        <select
          className="form-control select2 mb-3 mb-xxl-0"
          defaultValue="Select Company"
          onChange={e => handleSelectCompany(e.target)}
        >
          <option value="">Select Company</option>
          {(billingCompanyList || []).map(item => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      </Col>
      {/* <Col xxl={3} lg={3}>
        <select
          className="form-control select2 mb-3 mb-xxl-0"
          defaultValue="Select Company Location"
          onChange={e => handleSelectCompanyLocation(e.target)}
        >
          <option value="">Select Company Location</option>
          {(locationList || []).map(item => (
            <option key={item.id} value={item.location_id}>
              {item.location_title}
            </option>
          ))}
        </select>
      </Col> */}
      <Col xxl={2} lg={2}>
        <select
          className="form-control select2 mb-3 mb-xxl-0"
          defaultValue="Select Unit"
          onChange={e => handleSelectCustomer(e.target)}
        >
          <option value="">Select Unit</option>
          {(customerList || []).map(item => (
            <option key={item.id} value={item.id}>
              {item.unit_code}
            </option>
          ))}
        </select>
      </Col>

      {/* <Col xxl={2} lg={2}>
        <select
          className="form-control select2 mb-3 mb-xxl-0"
          defaultValue="Select Financial Year"
          onChange={e => handleFinancialYear(e.target)}
        >
          <option value="">Select Financial Year</option>
          {(financialYearList || []).map((item,index) => (
            <option key={index} value={item.financial_year}>
              {item}
            </option>
          ))}
        </select>
      </Col> */}
      <Col xxl={1} lg={1} className="mb-3">
        <div className="mb-3 mb-xxl-0">
          <button
            type="button"
            className="btn btn-soft-secondary w-100"
            onClick={handleSearch}
          >
            <i className="mdi mdi-filter-outline align-middle"></i> Submit
          </button>
        </div>
      </Col>
    </React.Fragment>
  )
}

export default JobListGlobalFilter
