import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import TableContainer from "./pendingBillListContainer"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap"

// import { Email, Tags, Projects } from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

import { isEmpty } from "lodash"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"
import { getPendingBillList } from "store/actions"
import LargeModal from "VIP PAGES/Dashboard/viewInvoiceModal"
import handleDownloadInvoice from "components/Common/handleDownloadInvoice"

const PendingBillList = () => {
  const navigate = useNavigate()
  document.title = "Pending Bill List "

  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const [clientId, setClientId] = useState(authUser ? authUser.id : "")
  const dispatch = useDispatch()
  const pendingBillListState = state =>
    state.billAndPayments.pendingBillList.billings
  const loadingState = state => state.billAndPayments.loading
  const pendingBill = useSelector(pendingBillListState) || []
  const loading = useSelector(loadingState) || []
  const [isLoading, setLoading] = useState(loading)

  useEffect(() => {
    const value = { client_id: clientId, user_type: authUser.user_type }
    dispatch(getPendingBillList(value))
  }, [dispatch])

  const [modalLarge, setModalLarge] = useState(false)

  const toggleModalLarge = () => setModalLarge(!modalLarge)
  const [billingId, setBillingId] = useState()

  const handleBillPreview = id => {
    setBillingId(id)
    toggleModalLarge()
  }
  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "srNo",
        sortBy: true,
        // filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.index + 1}
                </Link>
              </h5>
            </>
          )
        },
      },

      {
        Header: "Invoice No.",
        accessor: "invoice_no",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.bill_no}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Invoice date",
        accessor: "invoice_date",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.bill_date}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Company Name",
        accessor: "company_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.company_name}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Project Name",
        accessor: "project_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.project_name}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Unit Code",
        accessor: "unit_code",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {cellProps.row.original.unit_code}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Bill Amount",
        accessor: "all_total",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.all_total}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Amount Paid",
        accessor: "amount_rcd",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.amount_rcd}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Outstanding Amount",
        accessor: "outstanding_amt",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.outstanding_amt}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Credit Amount",
        accessor: "credit_amount",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.credit_amount}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Status",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link>
                <div
                  style={{
                    color:
                      cellProps.row.original.is_paid === 1 ? "green" : "red",
                  }}
                >
                  {cellProps.row.original.is_paid === 1 ? "Paid" : "Unpaid"}
                </div>{" "}
              </Link>
            </div>
          )
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <a
                // href={cellProps.row.original.download_document}
                // target="_blank"
                // rel="noopener noreferrer"
                // className="text-success"
                // download
                onClick={() => {
                  handleDownloadInvoice(cellProps.row.original.billing_id)
                }}
              >
                <i
                  className="mdi mdi-download font-size-18"
                  id={`downloadleaseagreementtooltip`}
                  style={{ color: "#3366cc" }}
                />
                <UncontrolledTooltip
                  placement="top"
                  target={`downloadleaseagreementtooltip`}
                >
                  Download
                </UncontrolledTooltip>
              </a>
              <a
                onClick={() => {
                  handleBillPreview(cellProps.row.original.billing_id)
                }}
              >
                <i
                  className="mdi mdi-eye font-size-18"
                  id={`viewleaseagreementtooltip-1`}
                  style={{ color: "#3366cc" }}
                />
                <UncontrolledTooltip
                  placement="top"
                  target={`viewleaseagreementtooltip-1`}
                >
                  View
                </UncontrolledTooltip>
              </a>
            </div>
          )
        },
      },
    ],
    []
  )

  const handleUserClick = arg => {
    const user = arg
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = users => {
    setDeleteId({ id: users })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (deleteId && deleteId.id) {
      dispatch(deletePendingBills(deleteId.id))
    }
    onPaginationPageChange(1)
    setDeleteModal(false)
    navigate("/pendingBills")
    dispatch(getPendingBillsList())
  }

  const handleUserClicks = () => {
    setUserList("")
    setIsEdit(false)
    toggle()
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <LargeModal
        isOpen={modalLarge}
        toggle={toggleModalLarge}
        billingId={billingId}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Bills & Payment" breadcrumbItem="Pending Bills" />

          <Row>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      isPagination={true}
                      columns={columns}
                      data={pendingBill || []}
                      isGlobalFilter={true}
                      isShowingPageLength={true}
                      isAddUserList={true}
                      iscustomPageSizeOptions={true}
                      handleUserClick={handleUserClicks}
                      customPageSize={10}
                      tableClass="table align-middle table-nowrap table-hover"
                      theadClass="table-light"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination pagination-rounded justify-content-end mt-4"
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PendingBillList
