import PropTypes from "prop-types"
import React, { useState } from "react"

import CarouselPage from "./CarouselPage"
import logoVip from "../../assets/images/VipClients/V1mallWithoutVinderNoBg.png"

import {
  Row,
  Col,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import * as Yup from "yup"
import { useFormik } from "formik"
import { loginUser } from "store/actions"

const Login = props => {
  const [passwordShow, setPasswordShow] = useState(false)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      login_username: "",
      password: "",
    },
    validationSchema: Yup.object({
      login_username: Yup.string().required("Please Enter Your username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async values => {
      try {
        const response = dispatch(loginUser(values, props.router.navigate))
      } catch (error) {
        console.error("Network Error:", error.message)
      }
    },
  })

  const dispatch = useDispatch()
  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    error: login.error,
  }))
  const { error } = useSelector(LoginProperties)

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />
            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div
                      className="mb-4 mb-md-5  "
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginInline: "auto",
                      }}
                    >
                      <img
                        src={logoVip}
                        alt=""
                        height="200"
                        className="logo-dark-element"
                      />
                      <img
                        src={logoVip}
                        alt=""
                        height="200"
                        className="logo-light-element"
                      />
                    </div>

                    <div className="my-auto">
                      <div>
                        <h5>Welcome Customer</h5>
                        <p className="text-muted">Login Portal</p>
                      </div>

                      <div className="mt-4">
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">Username</Label>
                            <Input
                              name="login_username"
                              className="form-control"
                              placeholder="Enter username"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.login_username || ""}
                              invalid={
                                validation.touched.login_username &&
                                validation.errors.login_username
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.login_username &&
                            validation.errors.login_username ? (
                              <FormFeedback type="invalid">
                                {validation.errors.login_username}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <div className="float-end">
                              <Link
                                to="/forget-password"
                                className="text-muted"
                              >
                                Forgot password?
                              </Link>
                            </div>
                            <Label className="form-label">Password</Label>
                            <div className="input-group auth-pass-inputgroup">
                              <Input
                                name="password"
                                value={validation.values.password || ""}
                                type={passwordShow ? "text" : "password"}
                                placeholder="Enter Password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.password &&
                                  validation.errors.password
                                    ? true
                                    : false
                                }
                              />
                              <button
                                onClick={() => setPasswordShow(!passwordShow)}
                                className="btn btn-light "
                                type="button"
                                id="password-addon"
                              >
                                <i className="mdi mdi-eye-outline"></i>
                              </button>
                            </div>
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>

                          {/* <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="auth-remember-check"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="auth-remember-check"
                            >
                              Remember me
                            </label>
                          </div> */}

                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block "
                              type="submit"
                            >
                              Log In
                            </button>
                          </div>
                        </Form>
                      </div>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Vinder Property Pvt Ltd.{" "}
                        <br />
                        Powered by Woodapples Software Solutions Pvt. Ltd
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
