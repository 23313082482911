import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { Col, Label, Modal, Row } from "reactstrap"
import { createSelector } from "reselect"
import { getLatestProjectUnit } from "store/actions"

const LargeModal = ({ isOpen, toggle }) => {
  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const [clientId, setClientId] = useState(authUser ? authUser.id : "")

  const dispatch = useDispatch()
  const projectUnitState = state =>
    state.customerProfilePreview.latestProjectUnit.project_units
  const loadingState = state => state.customerProfilePreview.loading
  const customerProfilePreviewProperties = createSelector(
    projectUnitState,
    loadingState,
    (projectUnit, loading) => ({
      projectUnit: projectUnit,
      loading: loading,
    })
  )
  const { projectUnit, loading } =
    useSelector(customerProfilePreviewProperties) || []
  const [isLoading, setLoading] = useState(loading)

  useEffect(() => {
    dispatch(getLatestProjectUnit({client_id :clientId,user_type:authUser.user_type}))
  }, [dispatch])
  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Project Unit Details
        </h5>
        <button
          onClick={toggle}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col>
            <Label>Project</Label>
            <p>{projectUnit && projectUnit.project_name}</p>
          </Col>
          <Col>
            <Label>Project Unit</Label>
            <p>{projectUnit && projectUnit.unit_code}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Minimum Monthly Rent</Label>
            <p>{projectUnit && projectUnit.minimum_monthly_rent}</p>
          </Col>
          <Col>
            <Label>Revenue Share</Label>
            <p>
              {projectUnit && projectUnit.revenue_share === 1
                ? "Monthly"
                : "Annualy"}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Revenue Share(%)</Label>
            <p>{projectUnit && projectUnit.revenue_share_percentage}</p>
          </Col>
          <Col>
            <Label>Rent Payment Schedule</Label>
            <p>
              {projectUnit && projectUnit?.rent_payment_schedule && (
                <span>
                  {projectUnit.rent_payment_schedule === 1
                    ? "Monthly"
                    : projectUnit.rent_payment_schedule === 2
                    ? "Quaterly"
                    : projectUnit.rent_payment_schedule === 3
                    ? "Half Yearly"
                    : projectUnit.rent_payment_schedule === 4
                    ? "Yearly"
                    : ""}
                </span>
              )}{" "}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Customer Lock-in Period</Label>
            <p>{projectUnit && projectUnit.customer_lock_in_period}</p>
          </Col>
          <Col>
            <Label>Notice Period</Label>
            <p>{projectUnit && projectUnit.notice_period}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Security Deposit For Rent</Label>
            <p>{projectUnit && projectUnit.security_deposit_for_rent}</p>
          </Col>
          <Col>
            <Label>Security Deposit For Electricity</Label>
            <p>{projectUnit && projectUnit.security_deposit_for_electricity}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Security Deposit For Maintenance</Label>
            <p>{projectUnit && projectUnit.security_deposit_for_maintenance}</p>
          </Col>
          <Col>
            <Label>Agreement Start Date</Label>
            <p>{projectUnit && projectUnit.agreement_start_date}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Agreement End Date</Label>
            <p>{projectUnit && projectUnit.agreement_end_date}</p>
          </Col>
          <Col>
            <Label>Rent Escalation(%)</Label>
            <p>{projectUnit && projectUnit.rent_escalation_percentage}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Registration cost for agreement on company</Label>
            <p>
              {projectUnit &&
                projectUnit.registration_cost_for_agreement_on_company}
            </p>
          </Col>
          <Col>
            <Label>Rent Escalation Period (Months)</Label>
            <p>{projectUnit && projectUnit.rent_escalation_period}</p>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

export default LargeModal
