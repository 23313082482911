import {
  GET_COMPANY_LOCATION_FILTER,
  GET_COMPANY_FILTER,
  GET_COMPANY_FILTER_SUCCESS,
  GET_COMPANY_FILTER_FAIL,
  GET_COMPANY_LOCATION_FILTER_SUCCESS,
  GET_COMPANY_LOCATION_FILTER_FAIL,
  GET_CUSTOMER_FILTER,
  GET_CUSTOMER_FILTER_SUCCESS,
  GET_CUSTOMER_FILTER_FAIL,
  GET_PROJECT_FILTER,
  GET_PROJECT_FILTER_SUCCESS,
  GET_PROJECT_FILTER_FAIL,
  GET_FINANCIAL_YEAR_FILTER,
  GET_FINANCIAL_YEAR_FILTER_SUCCESS,
  GET_FINANCIAL_YEAR_FILTER_FAIL,
} from "./actionTypes"
//GET COMPANY FILTER

export const getCompanyFilter = userId => ({
  type: GET_COMPANY_FILTER,
  userId,
})

export const getCompanyFilterSuccess = companyFilter => ({
  type: GET_COMPANY_FILTER_SUCCESS,
  payload: companyFilter,
})

export const getCompanyFilterFail = error => ({
  type: GET_COMPANY_FILTER_FAIL,
  payload: error,
})

//GET COMPANY LOCATION FILTER

export const getCompanyLocationFilter = userId => ({
  type: GET_COMPANY_LOCATION_FILTER,
  userId,
})

export const getCompanyLocationFilterSuccess = locationFilter => ({
  type: GET_COMPANY_LOCATION_FILTER_SUCCESS,
  payload: locationFilter,
})

export const getCompanyLocationFilterFail = error => ({
  type: GET_COMPANY_LOCATION_FILTER_FAIL,
  payload: error,
})

//GET CUSTOMER FILTER

export const getCustomerFilter = userId => ({
  type: GET_CUSTOMER_FILTER,
  userId,
})

export const getCustomerFilterSuccess = customerFilter => ({
  type: GET_CUSTOMER_FILTER_SUCCESS,
  payload: customerFilter,
})

export const getCustomerFilterFail = error => ({
  type: GET_CUSTOMER_FILTER_FAIL,
  payload: error,
})

//GET PROJECT FILTER

export const getProjectFilter = userId => ({
  type: GET_PROJECT_FILTER,
  userId,
})

export const getProjectFilterSuccess = projectFilter => ({
  type: GET_PROJECT_FILTER_SUCCESS,
  payload: projectFilter,
})

export const getProjectFilterFail = error => ({
  type: GET_PROJECT_FILTER_FAIL,
  payload: error,
})

//GET FINANCIAL YEAR FILTER

export const getFinancialYearFilter = userId => ({
  type: GET_FINANCIAL_YEAR_FILTER,
  userId,
})

export const getFinancialYearFilterSuccess = financialYearFilter => ({
  type: GET_FINANCIAL_YEAR_FILTER_SUCCESS,
  payload: financialYearFilter,
})

export const getFinancialYearFilterFail = error => ({
  type: GET_FINANCIAL_YEAR_FILTER_FAIL,
  payload: error,
})
