import { call, put, takeEvery } from "redux-saga/effects"
//  Redux States
import { GET_PENDING_BILL_LIST, GET_BILL_HISTORY_LIST, GET_BILL_RECEIPT_LIST, GET_RECEIPT_PREVIEW, GET_CREDIT_NOTE_LIST } from "./actionTypes"
import {
  getPendingBillListSuccess,
  getPendingBillListFail,
  getBillHistoryListSuccess,
  getBillHistoryListFail,
  getBillReceiptListSuccess,
  getBillReceiptListFail,
  getReceiptPreviewSuccess,
  getReceiptPreviewFail,
  getCreditNoteListSuccess,
  getCreditNoteListFail,
} from "./actions"

import { getPendingBillList, getBillHistoryList, getBillReceiptList, getReceiptPreview, getCreditNoteList } from "helpers/backend"

function* fetchPendingBillList({ clientId }) {
  try {
    const response = yield call(getPendingBillList, clientId)
    yield put(getPendingBillListSuccess(response))
  } catch (error) {
    yield put(getPendingBillListFail(error))
  }
}
function* fetchBillHistoryList({ clientId }) {
  try {
    const response = yield call(getBillHistoryList, clientId)
    yield put(getBillHistoryListSuccess(response))
  } catch (error) {
    yield put(getBillHistoryListFail(error))
  }
}
function* fetchBillReceiptList({ clientId }) {
  try {
    const response = yield call(getBillReceiptList, clientId)
    yield put(getBillReceiptListSuccess(response))
  } catch (error) {
    yield put(getBillReceiptListFail(error))
  }
}
function* fetchReceiptPreview({ clientId }) {
  try {
    const response = yield call(getReceiptPreview, clientId)
    yield put(getReceiptPreviewSuccess(response))
  } catch (error) {
    yield put(getReceiptPreviewFail(error))
  }
}

function* fetchCreditNoteList({ clientId }) {
  try {
    const response = yield call(getCreditNoteList, clientId)
    yield put(getCreditNoteListSuccess(response))
  } catch (error) {
    yield put(getCreditNoteListFail(error))
  }
}

function* billAndPaymentsSaga() {
  yield takeEvery(GET_PENDING_BILL_LIST, fetchPendingBillList)
  yield takeEvery(GET_BILL_HISTORY_LIST, fetchBillHistoryList)
  yield takeEvery(GET_BILL_RECEIPT_LIST, fetchBillReceiptList)
  yield takeEvery(GET_RECEIPT_PREVIEW, fetchReceiptPreview)
  yield takeEvery(GET_CREDIT_NOTE_LIST, fetchCreditNoteList)
}

export default billAndPaymentsSaga
