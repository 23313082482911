import formatNumberWithCommas from "components/Common/formatNumberWithCommas"
import { getDashboardData } from "helpers/backend"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"

import { Card, Col, CardBody } from "reactstrap"

const Count = () => {
  
  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const dispatch = useDispatch()

  const [AssignedUnits, setData1] = useState();
  const [outstandingAmount, setData2] = useState();
  const [serviceRequest, setData3] = useState();

  const fetchData = async () => {
    if (authUser) {
      try {
        const clientDataResponseForDashboard = await getDashboardData({client_id:authUser.id,user_type:authUser.user_type});
        setData1(clientDataResponseForDashboard.client_project_unit_count);
        setData2(clientDataResponseForDashboard?.outstanding_amount);
        setData3(clientDataResponseForDashboard.service_request_list);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    }
  };

  useEffect(() => {
    if (authUser) {
      fetchData();
    }
  }, []);

  return (
    <React.Fragment>
   
      <Col lg={4}>
        <Card className="blog-stats-wid">
          <Link to="/project-units">
            <CardBody>
              <div className="d-flex flex-wrap">
                <div className="me-3">
                  <p className="text-muted mb-2">Units Associated</p>
                  {/* <h5 className="mb-0  text-primary ">{count && count.all_project_count}</h5> */}
                  <h5 className="mb-0  text-dark ">{AssignedUnits}</h5>
                </div>
                <div className="avatar-sm ms-auto">
                  <div className="avatar-title bg-light rounded-circle text-success font-size-20">
                    <i className="mdi mdi-store-outline"></i>
                  </div>
                </div>
              </div>
            </CardBody>
          </Link>
        </Card>
      </Col>
      <Col lg={4}>
        <Card className="blog-stats-wid">
          <Link to="/bills-payments/pending-bills">
            <CardBody>
              <div className="d-flex flex-wrap">
                <div className="me-3">
                  <p className="text-muted mb-2">Outstanding Amount</p>
                  {/* <h5 className="mb-0 text-warning">{count && count.all_vacant_project_unit_count}</h5> */}
                  <h5 className="mb-0 text-dark">Rs {formatNumberWithCommas(outstandingAmount)}</h5>
                </div>
                <div className="avatar-sm ms-auto">
                  <div className="avatar-title bg-light rounded-circle text-warning font-size-20">
                    <i className="mdi mdi-receipt"></i>
                  </div>
                </div>
              </div>
            </CardBody>
          </Link>
        </Card>
      </Col>
      <Col lg={4}>
        <Card className="blog-stats-wid">
          <Link to="/service-requests/all-service-requests">
            <CardBody>
              <div className="d-flex flex-wrap">
                <div className="me-3">
                  <p className="text-muted mb-2 ">Service Requests</p>
                  {/* <h5 className="mb-0 text-danger">{count && count.all_customer_count}</h5> */}
                  <h5 className="mb-0 text-dark"> {serviceRequest}</h5>
                </div>
                <div className="avatar-sm ms-auto">
                  <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                    <i className="mdi mdi-room-service-outline"></i>
                  </div>
                </div>
              </div>
            </CardBody>
          </Link>
        </Card>
      </Col>
      
    </React.Fragment>
  )
}

export default Count
