import { API_URL } from "helpers/url"



const handleDownloadInvoice = billingId => {
    // Construct the URL for download
    const downloadUrl = `${API_URL}/api/download-invoice-pdf/${billingId}`

    // Create an anchor element
    const anchorElement = document.createElement("a")
    anchorElement.href = downloadUrl
    anchorElement.target = "_blank" // Open in a new tab
    anchorElement.download = "invoice.pdf" // Optional: specify a filename for the downloaded file

    // Append the anchor element to the document body (required in some browsers)
    document.body.appendChild(anchorElement)

    // Trigger a click event on the anchor element
    anchorElement.click()

    // Clean up: remove the anchor element from the document body
    document.body.removeChild(anchorElement)
  }

export default handleDownloadInvoice
