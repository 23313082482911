import {
  GET_CLIENT_DETAIL_SUCCESS,
  GET_CLIENT_DETAIL_FAIL,
  GET_LASTEST_PROJECT_UNIT_SUCCESS,
  GET_LASTEST_PROJECT_UNIT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  clientDetail: [],
  latestProjectUnit: [],
  error: {},
  loading: true,
}

const customerProfilePreview = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLIENT_DETAIL_SUCCESS:
      return {
        ...state,
        clientDetail: action.payload,
        loading: false,
      }

    case GET_CLIENT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_LASTEST_PROJECT_UNIT_SUCCESS:
      return {
        ...state,
        latestProjectUnit: action.payload,
        loading: false,
      }

    case GET_LASTEST_PROJECT_UNIT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default customerProfilePreview
