/* SERVICE_REQUESTS */
export const GET_SERVICE_REQUESTS = "GET_SERVICE_REQUESTS"
export const GET_SERVICE_REQUESTS_SUCCESS = "GET_SERVICE_REQUESTS_SUCCESS"
export const GET_SERVICE_REQUESTS_FAIL = "GET_SERVICE_REQUESTS_FAIL"

/* PENDING_REQUESTS */
export const GET_PENDING_REQUESTS = "GET_PENDING_REQUESTS"
export const GET_PENDING_REQUESTS_SUCCESS = "GET_PENDING_REQUESTS_SUCCESS"
export const GET_PENDING_REQUESTS_FAIL = "GET_PENDING_REQUESTS_FAIL"

/* SERVICE_REQUESTS_TYPE */
export const GET_SERVICE_REQUEST_TYPE = "GET_SERVICE_REQUEST_TYPE"
export const GET_SERVICE_REQUEST_TYPE_SUCCESS = "GET_SERVICE_REQUEST_TYPE_SUCCESS"
export const GET_SERVICE_REQUEST_TYPE_FAIL = "GET_SERVICE_REQUEST_TYPE_FAIL"

/* SERVICE_REQUESTS DETAIL*/
export const GET_SERVICE_REQUEST_DETAIL = "GET_SERVICE_REQUEST_DETAIL"
export const GET_SERVICE_REQUEST_DETAIL_SUCCESS = "GET_SERVICE_REQUEST_DETAIL_SUCCESS"
export const GET_SERVICE_REQUEST_DETAIL_FAIL = "GET_SERVICE_REQUEST_DETAIL_FAIL"

/**
 * add serviceRequest
 */
 export const ADD_NEW_SERVICE_REQUEST = "ADD_NEW_SERVICE_REQUEST"
 export const ADD_SERVICE_REQUEST_SUCCESS = "ADD_SERVICE_REQUEST_SUCCESS"
 export const ADD_SERVICE_REQUEST_FAIL = "ADD_SERVICE_REQUEST_FAIL"
 
 /**
  * Edit serviceRequest
  */
 export const UPDATE_SERVICE_REQUEST = "UPDATE_SERVICE_REQUEST"
 export const UPDATE_SERVICE_REQUEST_SUCCESS = "UPDATE_SERVICE_REQUEST_SUCCESS"
 export const UPDATE_SERVICE_REQUEST_FAIL = "UPDATE_SERVICE_REQUEST_FAIL"
 
 /**
  * Delete serviceRequest
  */
 export const DELETE_SERVICE_REQUEST = "DELETE_SERVICE_REQUEST"
 export const DELETE_SERVICE_REQUEST_SUCCESS = "DELETE_SERVICE_REQUEST_SUCCESS"
 export const DELETE_SERVICE_REQUEST_FAIL = "DELETE_SERVICE_REQUEST_FAIL"


 /* PROEJCT UNIT CATEGORY  */
export const GET_PROJECT_UNIT_CATEGORY = "GET_PROJECT_UNIT_CATEGORY"
export const GET_PROJECT_UNIT_CATEGORY_SUCCESS = "GET_PROJECT_UNIT_CATEGORY_SUCCESS"
export const GET_PROJECT_UNIT_CATEGORY_FAIL = "GET_PROJECT_UNIT_CATEGORY_FAIL"

 /* MAINTENANCE ISSUES */
export const GET_MAINTENANCE_ISSUES = "GET_MAINTENANCE_ISSUES"
export const GET_MAINTENANCE_ISSUES_SUCCESS = "GET_MAINTENANCE_ISSUES_SUCCESS"
export const GET_MAINTENANCE_ISSUES_FAIL = "GET_MAINTENANCE_ISSUES_FAIL"

 /* CLIENT INVOICES */
export const GET_CLIENT_INVOICES = "GET_CLIENT_INVOICES"
export const GET_CLIENT_INVOICES_SUCCESS = "GET_CLIENT_INVOICES_SUCCESS"
export const GET_CLIENT_INVOICES_FAIL = "GET_CLIENT_INVOICES_FAIL"

/* TICKETS*/
export const GET_SUPPORT_TICKET = "GET_SUPPORT_TICKET"
export const GET_SUPPORT_TICKET_SUCCESS = "GET_SUPPORT_TICKET_SUCCESS"
export const GET_SUPPORT_TICKET_FAIL = "GET_SUPPORT_TICKET_FAIL"

/* REMOVE PREVIOUS STATE*/

export const REMOVE_PREVIOUS_RESPONSE = 'REMOVE_PREVIOUS_RESPONSE';


/* GET ALL PROJECTS */
export const GET_ALL_PROJECTS = "GET_ALL_PROJECTS"
export const GET_ALL_PROJECTS_SUCCESS = "GET_ALL_PROJECTS_SUCCESS"
export const GET_ALL_PROJECTS_FAIL = "GET_ALL_PROJECTS_FAIL"

/* GET PROJECT UNIT */
export const GET_PROJECT_UNIT = "GET_PROJECT_UNIT"
export const GET_PROJECT_UNIT_SUCCESS = "GET_PROJECT_UNIT_SUCCESS"
export const GET_PROJECT_UNIT_FAIL = "GET_PROJECT_UNIT_FAIL"

/* GET CLIENT PROJECT */

export const GET_CLIENT_PROJECT_FOR_SERVICE_REQUEST ="GET_CLIENT_PROJECT_FOR_SERVICE_REQUEST"
export const GET_CLIENT_PROJECT_FOR_SERVICE_REQUEST_SUCCESS ="GET_CLIENT_PROJECT_FOR_SERVICE_REQUEST_SUCCESS"
export const GET_CLIENT_PROJECT_FOR_SERVICE_REQUEST_FAIL ="GET_CLIENT_PROJECT_FOR_SERVICE_REQUEST_FAIL"
