import React, { useEffect, useState } from "react"
import { Col, Button } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"

//flatpickr
import "flatpickr/dist/themes/material_blue.css"
import FlatPickr from "react-flatpickr"
import { Link } from "react-router-dom"
import { getServiceRequests, getServiceRequestTicketList } from "store/actions"
import { getProjectInCustomer } from "helpers/backend"

const JobListGlobalFilter = ({ setGlobalFilter }) => {
  const serviceRequestState = state =>
    state.serviceRequests.serviceRequests.service_requests

  const dispatch = useDispatch()
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")))

  const [projectList, setProjectList] = useState([])
  const [statusValue, setStatusValue] = useState("")
  const [project, setProject] = useState('')

  const handleSelectStatus = ele => {
    setStatusValue(ele.value || "")
  }

  const handleProject = ele => {
    let data = ele.value
    setProject(data || "")
  }

  const handleSearch = () => {
    const value = {
      project_id: project,
      client_id: user.id,
      user_type: user.user_type,
      status: statusValue,
    }
    console.log(value)
    dispatch(getServiceRequests(value))
  }

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        try {
          const response = await getProjectInCustomer(user.id)
          setProjectList(response.projects)
        } catch (error) {
          console.error("Error fetching project list:", error)
        }
      }
    }

    fetchData()
  }, [user])

  return (
    <React.Fragment>
      <Col xxl={2} lg={6}>
        <select
          className="form-control select2 mb-3 mb-xxl-0"
          defaultValue="Status"
          onChange={e => handleSelectStatus(e.target)}
        >
          <option value="">Status</option>
          <option value="">All</option>
          <option value="1">Open</option>
          <option value="2">Ticket Created</option>
          <option value="3">In Progress</option>
          <option value="4">Ticket Closed</option>
          <option value="5">Declined</option>
          <option value="6">Assigned</option>
        </select>
      </Col>
      <Col xxl={2} lg={4}>
        <select
          className="form-control select2 mb-3 mb-xxl-0"
          defaultValue="Select Project"
          onChange={e => handleProject(e.target)}
        >
          <option value="">Select Project</option>
          {projectList &&
            projectList.map((project, index) => (
              <option key={index} value={project.id}>
                {project.project_name}
              </option>
            ))}
        </select>
      </Col>

      <Col xxl={1} lg={4}>
        <div className="mb-3 mb-xxl-0">
          <button
            type="button"
            className="btn btn-soft-secondary w-100"
            onClick={handleSearch}
          >
            <i className="mdi mdi-filter-outline align-middle"></i> Submit
          </button>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default JobListGlobalFilter
