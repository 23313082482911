import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import TableContainer from "./pendingRequestsListContainer"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"
import { deleteServiceRequest, getPendingRequests } from "store/actions"

const PendingRequestsList = () => {
  const navigate = useNavigate()
  document.title = "Pending Request List"

  const dispatch = useDispatch()
  const serviceRequestState = state =>
    state.serviceRequests.pendingRequests.service_requests

  const serviceRequest = useSelector(serviceRequestState) || []

  const loadingState = state => state.serviceRequests.loading
  const loading = useSelector(loadingState) || []
  const [isLoading, setLoading] = useState(loading)

  useEffect(() => {
    dispatch(getPendingRequests())
  }, [dispatch])

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "srNo",
        sortBy: true,
        // filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {" "}
                  {cellProps.row.index + 1}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Service Code",
        accessor: "srq_code",
        sortBy: true,
        // filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {" "}
                  {cellProps.row.original.srq_code}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Customer",
        accessor: "customer_name",
        sortBy: true,
        // filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {" "}
                  {cellProps.row.original.customer_name}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Type",
        accessor: "type_of_service",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link
                  className="text-dark"
                  to={`/service-request/view-service/${cellProps.row.original.id}`}
                >
                  {cellProps.row.original.service_type}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Request made on",
        accessor: "created_on",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark">
                  {new Date(cellProps.row.original.created_on).toLocaleString()}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Status",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to={`/service-requests/view-service-requests/${cellProps.row.original.id}`}
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original
                  handleUserClick(userData)
                }}
              >
                {cellProps.row.original.status_name}
              </Link>
            </div>
          )
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original.id
                  onClickDelete(userData)
                }}
              >
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  style={{ color: "#3366cc" }}
                />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
              <Link
                to={`/service-requests/view-service-requests/${cellProps.row.original.id}`}
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original
                  handleUserClick(userData)
                }}
              >
                <i
                  className="mdi mdi-chevron-right"
                  id="viewtooltip"
                  style={{ fontSize: "18px", color: "#3366cc" }}
                />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const handleUserClick = arg => {
    const user = arg
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = users => {
    setDeleteId({ id: users })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (deleteId && deleteId.id) {
      dispatch(deleteServiceRequest(deleteId.id))
    }
    onPaginationPageChange(1)
    setDeleteModal(false)
    dispatch(getPendingRequests())
  }

  const handleUserClicks = () => {
    setUserList("")
    setIsEdit(false)
    toggle()
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Service Request"
            breadcrumbItem="Pending Requests"
          />

          <Row>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      isPagination={true}
                      columns={columns}
                      data={serviceRequest}
                      isGlobalFilter={true}
                      isShowingPageLength={true}
                      isAddUserList={true}
                      iscustomPageSizeOptions={true}
                      handleUserClick={handleUserClicks}
                      customPageSize={10}
                      tableClass="table align-middle table-nowrap table-hover"
                      theadClass="table-light"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination pagination-rounded justify-content-end mt-4"
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PendingRequestsList
