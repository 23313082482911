import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap"
import UpcomingPayment from "./latestInvoice"
import Carousel from "./Corousel"
import PreviousInvoices from "./PreviousInvoice"
import Requests from "./Requests"
import Count from "./Components/count"
import Invoices from "./Components/invoices"
import ServiceRequest from "./Components/serviceRequest"
import WaterConsumption from "./Components/waterConsumption"
import ElectricityConsumption from "./Components/ElectricityConsumption"
import GasConsumption from "./Components/GasConsumption"
import DgConsumption from "./Components/dgConsumption"

const Dashboard = props => {
  document.title = "Dashboard "
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Count />
          </Row>
          <Row>
            <Col xl="8">
              <Invoices />

              <ServiceRequest />
            </Col>
            <Col xl="4" className="mb-4">
              <Carousel />
           </Col>
          </Row>
          <Row>
            <Col xl="12">
              <Row>
                <WaterConsumption />
                <ElectricityConsumption />
              </Row>
              <Row>
                
                <GasConsumption />
                <DgConsumption />
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
