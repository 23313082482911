import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Label,
  Modal,
  Row,
  Table,
} from "reactstrap"
import { getInvoiceData, getLatestInvoice } from "store/actions"
import qrCode from "../../assets/images/VipClients/qrCode.jpg"
import { createSelector } from "reselect"
import { ToWords } from "to-words"
import { isEmpty } from "lodash"

const LargeModal = ({ isOpen, toggle ,billingId }) => {
  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const [clientId, setClientId] = useState(authUser ? authUser.id : "")
  const dispatch = useDispatch()

  const latestInvoicePreviewState = state => state.Dashboard
  const latestInvoiceProperties = createSelector(
    latestInvoicePreviewState,
    billing => ({
      invoiceDetail: billing?.invoiceData?.billing,
      customerDetail: billing?.invoiceData?.billing?.client,
      companyDetail: billing?.invoiceData?.billing?.company || [],
    })
  )

  const { invoiceDetail, customerDetail, companyDetail } = useSelector(
    latestInvoiceProperties
  )

  console.log("Invoice Detail", invoiceDetail)
  console.log("Customer Detail", customerDetail)
  console.log("Company Detail", companyDetail)
  console.log("billingId", billingId)

  useEffect(() => {
    if(billingId){
    dispatch(getInvoiceData(billingId))
    }
  }, [billingId])

  //Print the Invoice
  const printInvoice = () => {
    window.print()
  }
  const toWords = new ToWords()

  const formatNumberWithCommas = number => {
    // If number is null or empty, return it as it is
    if (!number) {
      return number
    }

    // Convert number to string
    let numStr = String(number)

    // Split the string into integer and decimal parts
    let parts = numStr.split(".")

    // Format the integer part with commas
    let integerPart = parts[0]
    let lastThreeDigits = integerPart.slice(-3) // Get the last three digits
    let remainingDigits = integerPart.slice(0, -3) // Get the remaining digits
    let formattedInteger =
      remainingDigits !== ""
        ? remainingDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          lastThreeDigits
        : lastThreeDigits

    // Combine integer and decimal parts with a dot
    let formattedNumber = formattedInteger + (parts[1] ? "." + parts[1] : "")

    return formattedNumber
  }

  const formatNumberForRoundOff = (num) => {
    if (num === null || num === undefined || isNaN(num)) {
      return "NA";
    }
  
    // Ensure num is a number before applying toFixed
    const rounded = Number(num).toFixed(2);
    // Convert to string and format with commas
    return rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Invoice
        </h5>
        <button
          onClick={toggle}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
      {!isEmpty(invoiceDetail) && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {/* <Row>
                      <Col className="mt-5">
                        <table>
                          <tbody>
                            <tr>
                              <td style={{ whiteSpace: "nowrap" }}>
                                <strong>IRN:</strong>
                              </td>
                              <td>
                                23f498ee41441ecad30f72ba5b9907506c3df70a17b0e0dff46b76a786400
                              </td>
                            </tr>
                            <tr>
                              <td style={{ whiteSpace: "nowrap" }}>
                                <strong>Ack No.:</strong>
                              </td>
                              <td>1231123123123123</td>
                            </tr>
                            <tr>
                              <td style={{ whiteSpace: "nowrap" }}>
                                <strong>Ack Date:</strong>
                              </td>
                              <td>14-Mar-24</td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                      <Col className="text-end">
                        <img
                          src={qrCode}
                          alt="logo-dark"
                          style={{ height: "150px" }}
                        />
                      </Col>
                    </Row> */}
                    {/* <hr /> */}
                    <CardTitle className="text-center">Tax Invoice</CardTitle>

                    <table
                      style={{
                        width: "100%",
                        // border: "1px solid black",
                        borderCollapse: "collapse",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              padding: "5px",
                              borderTop: "1px solid black",
                              borderBottom: "1px solid black",
                              borderLeft: "1px solid black",
                              borderRight: "1px solid black",
                            }}
                            rowSpan="3"
                          >
                            <strong>
                              {companyDetail && companyDetail.name}
                            </strong>
                            <br />
                            {companyDetail &&
                              companyDetail?.gst_location_details
                                ?.location_title}
                            <br />
                            GSTIN/UIN:{" "}
                            {companyDetail &&
                              companyDetail?.gst_location_details?.gst_number}
                            <br />
                            State Name:{" "}
                            {companyDetail &&
                              companyDetail?.gst_place_of_supply?.state_supply}
                            , Code:{" "}
                            {companyDetail &&
                              companyDetail?.gst_place_of_supply
                                ?.gst_state_code}
                            <br />
                            CIN: {companyDetail && companyDetail?.cin_no}
                            <br />
                            E-Mail:{" "}
                            {companyDetail &&
                              companyDetail?.gst_location_details
                                ?.account_email}
                            <br />
                          </td>

                          <td
                            style={{
                              padding: "5px",
                              borderTop: "1px solid black",
                              borderBottom: "1px solid black",
                              borderLeft: "1px solid black",
                              borderRight: "1px solid black",
                            }}
                          >
                            Invoice No. <br />
                            <strong>
                              {invoiceDetail && invoiceDetail.bill_no}
                            </strong>
                          </td>

                          <td
                            style={{
                              padding: "5px",
                              borderTop: "1px solid black",
                              borderBottom: "1px solid black",
                              borderLeft: "1px solid black",
                              borderRight: "1px solid black",
                            }}
                          >
                            Dated <br />
                            <strong>
                              {invoiceDetail && invoiceDetail.bill_date}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              padding: "5px",
                              borderTop: "1px solid black",
                              borderBottom: "1px solid black",
                              borderLeft: "1px solid black",
                              borderRight: "1px solid black",
                            }}
                          >
                            Delivery Note <br />
                            <strong>
                              {(invoiceDetail &&
                                invoiceDetail?.delivery_note) ||
                                "NA"}
                            </strong>{" "}
                          </td>

                          <td
                            style={{
                              padding: "5px",
                              borderTop: "1px solid black",
                              borderBottom: "1px solid black",
                              borderLeft: "1px solid black",
                              borderRight: "1px solid black",
                            }}
                          >
                            Due Date <br />
                            <strong>
                              {(invoiceDetail && invoiceDetail.bill_due_date) ||
                                "NA"}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              padding: "5px",
                              borderTop: "1px solid black",
                              borderBottom: "1px solid black",
                              borderLeft: "1px solid black",
                              borderRight: "1px solid black",
                            }}
                          >
                            Reference No. & Date <br />
                            <strong>
                              {invoiceDetail?.reference_no &&
                              invoiceDetail?.reference_date
                                ? `${invoiceDetail.reference_no} ${invoiceDetail.reference_date}`
                                : "NA"}
                            </strong>{" "}
                          </td>

                          <td
                            style={{
                              padding: "5px",
                              borderTop: "1px solid black",
                              borderBottom: "1px solid black",
                              borderLeft: "1px solid black",
                              borderRight: "1px solid black",
                            }}
                          >
                            Other References <br />
                            <strong>
                              {(invoiceDetail &&
                                invoiceDetail.other_references) ||
                                "NA"}
                            </strong>
                          </td>
                        </tr>

                        <tr>
                          <td
                            style={{
                              padding: "5px",
                              borderTop: "1px solid black",
                              borderLeft: "1px solid black",
                              borderRight: "1px solid black",
                            }}
                            rowSpan="3"
                          >
                            Buyer (Bill to) <br />
                            <strong>
                              {customerDetail && customerDetail.customer_name}
                            </strong>
                            <br />
                            {customerDetail && customerDetail.address}
                            <br />
                            GSTIN/UIN: {customerDetail && customerDetail.gst_no}
                            <br />
                            State Name:{" "}
                            {customerDetail &&
                              customerDetail.gst_place_of_supply.state_supply}
                            , Code:{" "}
                            {customerDetail &&
                              customerDetail.gst_place_of_supply.gst_state_code}
                            <br />
                            E-Mail:{" "}
                            {customerDetail && customerDetail.email_address}
                            <br />
                          </td>
                          <td
                            style={{
                              padding: "5px",
                              borderTop: "1px solid black",
                              borderLeft: "1px solid black",
                              borderRight: "1px solid black",
                              borderBottom: "1px solid black",
                            }}
                          >
                            Buyers Order No <br />
                            <strong>
                              {(invoiceDetail &&
                                invoiceDetail.buyers_order_no) ||
                                "NA"}
                            </strong>
                          </td>

                          <td
                            style={{
                              padding: "5px",
                              borderTop: "1px solid black",
                              borderLeft: "1px solid black",
                              borderRight: "1px solid black",
                              borderBottom: "1px solid black",
                            }}
                          >
                            Dated
                            <br />
                            <strong>
                              {(invoiceDetail && invoiceDetail.Dated) || "NA"}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              padding: "5px",
                              borderTop: "1px solid black",
                              borderLeft: "1px solid black",
                              borderRight: "1px solid black",
                              borderBottom: "1px solid black",
                            }}
                          >
                            Dispatch Doc No <br />
                            <strong>
                              {(invoiceDetail &&
                                invoiceDetail.dispatch_doc_no) ||
                                "NA"}
                            </strong>
                          </td>

                          <td
                            style={{
                              padding: "5px",
                              borderTop: "1px solid black",
                              borderLeft: "1px solid black",
                              borderRight: "1px solid black",
                              borderBottom: "1px solid black",
                            }}
                          >
                            Delivery Note Date
                            <br />
                            <strong>
                              {(invoiceDetail &&
                                invoiceDetail.delivery_note_date) ||
                                "NA"}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              padding: "5px",
                              borderTop: "1px solid black",
                              borderLeft: "1px solid black",
                              borderRight: "1px solid black",
                            }}
                          >
                            Dispatched through <br />
                            <strong>
                              {(invoiceDetail &&
                                invoiceDetail.dispatched_through) ||
                                "NA"}
                            </strong>
                          </td>

                          <td
                            style={{
                              padding: "5px",
                              borderTop: "1px solid black",
                              borderLeft: "1px solid black",
                              borderRight: "1px solid black",
                            }}
                          >
                            Destination
                            <br />
                            <strong>
                              {(invoiceDetail && invoiceDetail.destination) ||
                                "NA"}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        borderRight: "1px solid black",
                        borderLeft: "1px solid black",
                        borderBottom: "1px solid black",
                        borderTop: "1px solid black",
                      }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              width: "3%",
                              borderRight: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            Sr.
                            <br />
                            No
                          </th>
                          <th
                            style={{
                              width: "37%",
                              borderRight: "1px solid black",
                              textAlign: "center",
                            }}
                            colSpan={2}
                          >
                            Particulars
                          </th>
                          <th
                            style={{
                              width: "10%",
                              borderRight: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            Unit
                          </th>
                          <th
                            style={{
                              width: "10%",
                              borderRight: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            Rate/Unit
                          </th>
                          <th
                            style={{
                              width: "10%",
                              borderRight: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            HSN/SAC
                          </th>
                          <th
                            style={{
                              width: "10%",
                              borderRight: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            Amount
                          </th>
                          <th
                            style={{
                              width: "10%",
                              borderRight: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            Discount
                          </th>
                          <th
                            style={{
                              width: "20%",
                              borderRight: "1px solid black",
                              textAlign: "center",
                            }}
                          >
                            Taxable Amount
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {invoiceDetail &&
                          invoiceDetail?.billing_components.map((item, key) => (
                            <tr key={key}>
                              <td
                                style={{
                                  width: "3%",
                                  border: "1px solid black",
                                  textAlign: "center",
                                  padding: "8px",
                                }}
                              >
                                {key + 1}
                              </td>
                              <td
                                style={{
                                  width: "37%",
                                  border: "1px solid black",
                                  textAlign: "left",
                                  padding: "8px",
                                }}
                                colSpan={2}
                              >
                                {item.invoice_type}
                              </td>
                              <td
                                style={{
                                  width: "10%",
                                  border: "1px solid black",
                                  textAlign: "center",
                                  padding: "8px",
                                }}
                              >
                                {item.unit}
                              </td>
                              <td
                                style={{
                                  width: "10%",
                                  border: "1px solid black",
                                  textAlign: "center",
                                  padding: "8px",
                                }}
                              >
                                {formatNumberWithCommas(item.rate_per_unit)}
                              </td>
                              <td
                                style={{
                                  width: "10%",
                                  border: "1px solid black",
                                  textAlign: "center",
                                  padding: "8px",
                                }}
                              >
                                {item.sac_hsm_code}
                              </td>
                              <td
                                style={{
                                  width: "10%",
                                  border: "1px solid black",
                                  textAlign: "right",
                                  padding: "8px",
                                }}
                              >
                                {formatNumberWithCommas(item.total)}
                              </td>
                              <td
                                style={{
                                  width: "10%",
                                  border: "1px solid black",
                                  textAlign: "right",
                                  padding: "8px",
                                }}
                              >
                                {formatNumberWithCommas(item.discount)}
                              </td>
                              <td
                                style={{
                                  width: "20%",
                                  border: "1px solid black",
                                  textAlign: "right",
                                  padding: "8px",
                                }}
                              >
                                {formatNumberWithCommas(item.discounted_amount)}
                              </td>
                            </tr>
                          ))}

                        {/* <tr>
                          <td
                            colSpan="6"
                            style={{
                              border: "1px solid black",
                              textAlign: "end",
                              fontWeight: "bold",
                              padding: "8px",
                            }}
                          >
                            Total Bill Amount
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              textAlign: "center",
                              padding: "8px",
                            }}
                          >
                            {invoiceDetail &&
                              formatNumberWithCommas(invoiceDetail.subtotal)}
                          </td>
                        </tr> */}
                        {/* <tr>
                          <td
                            colSpan="6"
                            style={{
                              border: "1px solid black",
                              textAlign: "end",
                              fontWeight: "bold",
                              padding: "8px",
                            }}
                          >
                            Discount
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              textAlign: "center",
                              padding: "8px",
                            }}
                          >
                            {(invoiceDetail &&
                              formatNumberWithCommas(invoiceDetail.discount)) ||
                              "NA"}
                          </td>
                        </tr> */}
                        <tr>
                          <td
                            colSpan="8"
                            style={{
                              border: "1px solid black",
                              textAlign: "end",
                              fontWeight: "bold",
                              padding: "8px",
                            }}
                          >
                            Taxable Amount
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              textAlign: "right",
                              padding: "8px",
                            }}
                          >
                            {invoiceDetail &&
                              formatNumberWithCommas(
                                invoiceDetail.discounted_subtotal
                              )}
                          </td>
                        </tr>
                        {invoiceDetail && invoiceDetail?.cgst_amount > 0 && (
                          <tr>
                            <td
                              colSpan="8"
                              style={{
                                border: "1px solid black",
                                textAlign: "end",
                                fontWeight: "bold",
                                padding: "8px",
                              }}
                            >
                              CGST(Outward)
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                textAlign: "right",
                                padding: "8px",
                              }}
                            >
                              {invoiceDetail &&
                                formatNumberWithCommas(
                                  invoiceDetail.cgst_amount
                                )}
                            </td>
                          </tr>
                        )}
                        {invoiceDetail.sgst_amount > 0 && (
                          <tr>
                            <td
                              colSpan="8"
                              style={{
                                border: "1px solid black",
                                textAlign: "end",
                                fontWeight: "bold",
                                padding: "8px",
                              }}
                            >
                              SGST(Outward)
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                textAlign: "right",
                                padding: "8px",
                              }}
                            >
                              {invoiceDetail &&
                                formatNumberWithCommas(
                                  invoiceDetail.sgst_amount
                                )}
                            </td>
                          </tr>
                        )}
                        {invoiceDetail.igst_amount > 0 && (
                          <tr>
                            <td
                              colSpan="8"
                              style={{
                                border: "1px solid black",
                                textAlign: "end",
                                fontWeight: "bold",
                                padding: "8px",
                              }}
                            >
                              IGST
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                textAlign: "right",
                                padding: "8px",
                              }}
                            >
                              {invoiceDetail &&
                                formatNumberWithCommas(
                                  invoiceDetail.igst_amount
                                )}
                            </td>
                          </tr>
                        )}

                        <tr>
                          <td
                            colSpan="8"
                            style={{
                              border: "1px solid black",
                              textAlign: "end",
                              fontWeight: "bold",
                              padding: "8px",
                            }}
                          >
                            Less: Round Off
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              textAlign: "right",
                              padding: "8px",
                            }}
                          >
                            {(invoiceDetail &&
                              formatNumberForRoundOff(
                                invoiceDetail.round_of_amount
                              )) ||
                              "NA"}
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan="8"
                            style={{
                              border: "1px solid black",
                              textAlign: "end",
                              fontWeight: "bold",
                              padding: "8px",
                            }}
                          >
                            Total
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              textAlign: "right",
                              padding: "8px",
                            }}
                          >
                            {formatNumberWithCommas(invoiceDetail.all_total)}
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan="8"
                            style={{
                              padding: "8px",
                            }}
                          >
                            Amount Chargeable (in words) : <br />
                            <strong>
                              {invoiceDetail &&
                                toWords.convert(invoiceDetail.all_total, {
                                  currency: true,
                                  ignoreZeroCurrency: true,
                                })}
                            </strong>
                          </td>
                          <td
                            style={{
                              textAlign: "end",
                            }}
                          >
                            E & O.E
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        borderRight: "1px solid black",
                        borderLeft: "1px solid black",
                        borderBottom: "1px solid black",
                        // borderTop: "1px solid black",
                      }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              borderLeft: "1px solid black",
                              borderTop: "1px solid black",
                              borderBottom: "1px solid black",
                              borderRight: "1px solid black",
                              textAlign: "center",
                              padding: "8px",
                            }}
                            rowSpan={2}
                            colSpan={2}
                          >
                            HSN/SAC
                          </th>
                          <th
                            style={{
                              borderLeft: "1px solid black",
                              borderTop: "1px solid black",
                              borderBottom: "1px solid black",
                              borderRight: "1px solid black",
                              textAlign: "center",
                              padding: "8px",
                            }}
                            rowSpan={2}
                          >
                            Taxable <br /> Value
                          </th>
                          <th
                            style={{
                              borderLeft: "1px solid black",
                              borderTop: "1px solid black",
                              borderBottom: "1px solid black",
                              borderRight: "1px solid black",
                              textAlign: "center",
                              padding: "8px",
                            }}
                            colSpan={2}
                          >
                            CGST
                          </th>
                          <th
                            style={{
                              borderLeft: "1px solid black",
                              borderTop: "1px solid black",
                              borderBottom: "1px solid black",
                              borderRight: "1px solid black",
                              textAlign: "center",
                              padding: "8px",
                            }}
                            colSpan={2}
                          >
                            SGST/UTGST
                          </th>
                          <th
                            style={{
                              borderLeft: "1px solid black",
                              borderTop: "1px solid black",
                              borderBottom: "1px solid black",
                              borderRight: "1px solid black",
                              textAlign: "center",
                              padding: "8px",
                            }}
                            rowSpan={2}
                            colSpan={2}
                          >
                            Total <br /> Tax Amount
                          </th>
                        </tr>
                        <tr>
                          <th
                            style={{
                              borderLeft: "1px solid black",
                              borderTop: "1px solid black",
                              borderBottom: "1px solid black",
                              borderRight: "1px solid black",
                              textAlign: "center",
                              padding: "8px",
                            }}
                          >
                            Rate
                          </th>
                          <th
                            style={{
                              borderLeft: "1px solid black",
                              borderTop: "1px solid black",
                              borderBottom: "1px solid black",
                              borderRight: "1px solid black",
                              textAlign: "center",
                              padding: "8px",
                            }}
                          >
                            Amount
                          </th>
                          <th
                            style={{
                              borderLeft: "1px solid black",
                              borderTop: "1px solid black",
                              borderBottom: "1px solid black",
                              borderRight: "1px solid black",
                              textAlign: "center",
                              padding: "8px",
                            }}
                          >
                            Rate
                          </th>
                          <th
                            style={{
                              borderLeft: "1px solid black",
                              borderTop: "1px solid black",
                              borderBottom: "1px solid black",
                              borderRight: "1px solid black",
                              textAlign: "center",
                              padding: "8px",
                            }}
                          >
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoiceDetail?.billing_components?.map((item, key) => (
                          <tr key={key}>
                            <td
                              style={{
                                border: "1px solid black",
                                textAlign: "center",
                              }}
                              colSpan={2}
                            >
                              {item.sac_hsm_code}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                textAlign: "right",
                                padding: "5px",
                              }}
                            >
                              {formatNumberWithCommas(item.discounted_amount)}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                textAlign: "center",
                              }}
                            >
                              {invoiceDetail.gst_type === "regular" ? (
                                <span>{item.gst_percentage / 2}%</span>
                              ) : (
                                <span>-</span>
                              )}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                textAlign:
                                  invoiceDetail.gst_type === "regular"
                                    ? "right"
                                    : "center",
                                padding: "5px",
                                padding: "5px",
                              }}
                            >
                              {invoiceDetail.gst_type === "regular" ? (
                                <span>
                                  {formatNumberWithCommas(item.gst_amount / 2)}
                                </span>
                              ) : (
                                <span>-</span>
                              )}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                textAlign: "center",
                              }}
                            >
                              {invoiceDetail.gst_type === "regular" ? (
                                <span>{item.gst_percentage / 2}%</span>
                              ) : (
                                <span>-</span>
                              )}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                textAlign:
                                  invoiceDetail.gst_type === "regular"
                                    ? "right"
                                    : "center",
                                padding: "5px",
                              }}
                            >
                              {invoiceDetail.gst_type === "regular" ? (
                                <span>
                                  {formatNumberWithCommas(item.gst_amount / 2)}
                                </span>
                              ) : (
                                <span>-</span>
                              )}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                textAlign: "right",
                                padding: "5px",
                              }}
                              colSpan={2}
                            >
                              {formatNumberWithCommas(item.gst_amount)}{" "}
                              {invoiceDetail.gst_type === "igst" && (
                                <span>({item.gst_percentage}%)</span>
                              )}
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td
                            style={{
                              border: "1px solid black",
                              textAlign: "center",
                            }}
                            colSpan={2}
                          >
                            <strong>Total</strong>
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              textAlign: "right",
                              padding: "5px",
                            }}
                          >
                            <strong>
                              {" "}
                              {formatNumberWithCommas(
                                invoiceDetail.discounted_subtotal
                              )}
                            </strong>
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              textAlign: "center",
                            }}
                          ></td>
                          <td
                            style={{
                              border: "1px solid black",
                              textAlign: "right",
                              padding: "5px",
                            }}
                          >
                            <strong>
                              {" "}
                              {invoiceDetail &&
                                invoiceDetail?.gst_type === "regular" && (
                                  <span>
                                    {formatNumberWithCommas(
                                      invoiceDetail.tax_amount / 2
                                    )}
                                  </span>
                                )}
                            </strong>
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              textAlign: "center",
                            }}
                          ></td>
                          <td
                            style={{
                              border: "1px solid black",
                              textAlign: "right",
                              padding: "5px",
                            }}
                          >
                            <strong>
                              {" "}
                              {invoiceDetail?.gst_type === "regular" && (
                                <span>
                                  {formatNumberWithCommas(
                                    invoiceDetail.tax_amount / 2
                                  )}
                                </span>
                              )}
                            </strong>
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              textAlign: "right",
                              padding: "5px",
                            }}
                            colSpan={2}
                          >
                            <strong>
                              {formatNumberWithCommas(invoiceDetail.tax_amount)}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan="8"
                            style={{
                              padding: "8px",
                              borderBottom: "1px solid",
                            }}
                          >
                            Tax Amount (in words) :
                            <strong>
                              {invoiceDetail &&
                                toWords.convert(invoiceDetail.tax_amount, {
                                  currency: true,
                                  ignoreZeroCurrency: true,
                                })}
                              {/* {convertRupeesToWords(invoiceDetail.all_total)} */}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan="3"
                            style={{
                              padding: "8px",
                            }}
                          >
                            <i>Remarks:</i> <br />
                            <br />
                            {invoiceDetail && invoiceDetail.remarks}
                            <br />
                            Company's Pan :{" "}
                            <strong>
                              {" "}
                              {companyDetail && companyDetail.pan_no}{" "}
                            </strong>{" "}
                          </td>
                          <td
                            colSpan="5"
                            style={{
                              borderLeft: "1px solid",

                              whiteSpace: "nowrap",
                              padding: "8px",
                            }}
                          >
                            <tr>
                              <td>Company's Bank Details </td>
                            </tr>
                            
                            <tr>
                              <td> A/C Holder's Name  </td>
                              <td>  : </td>
                              <td>
                                <strong>
                                  {companyDetail && companyDetail?.name}
                                </strong>
                              </td>
                            </tr>
                            <tr>
                              <td> Bank Name  </td>
                              <td>  : </td>

                              <td>
                                <strong>
                                {companyDetail &&
                                companyDetail?.gst_location_details
                                  ?.bank_name}
                                </strong>
                              </td>
                            </tr>
                            <tr>
                              <td> A/c No  </td>
                              <td>  : </td>

                              <td>
                                <strong>
                                {companyDetail &&
                                companyDetail?.gst_location_details
                                  ?.bank_account_no}
                                </strong>
                              </td>
                            </tr>
                            <tr>
                              <td> Branch & IFSC Code </td>
                              <td>  : </td>

                              <td>
                                <strong>
                                {companyDetail &&
                                companyDetail?.gst_location_details
                                  ?.bank_branch}{" "}
                              &{" "}
                              {companyDetail &&
                                companyDetail?.gst_location_details
                                  ?.bank_ifsc}{" "}
                                </strong>
                              </td>
                            </tr>
                            
                            
                          
                           
                          

                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan="3"
                            style={{
                              // whiteSpace: "nowrap" ,
                              padding: "8px",
                            }}
                          >
                            <u>Declaration : </u>
                            <br />
                            We declare that this invoice shows the actual price
                            of the goods/services described and that all
                            particulars are true and correct.
                          </td>
                          <td
                            colSpan="5"
                            style={{
                              padding: "8px",
                              border: "1px solid black",
                              textAlign: "Right",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <strong>
                              For{" "}
                              {companyDetail &&
                                companyDetail?.name.toUpperCase()}
                            </strong>{" "}
                            <br />
                            <br />
                            <br /> <br />
                            <strong>Authorised Signatory</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="text-center mt-2">
                      <span>
                        <strong>
                          {" "}
                          SUBJECT TO{" "}
                          {companyDetail?.gst_place_of_supply?.state_supply.toUpperCase()}{" "}
                          JURISDICTION
                        </strong>
                      </span>
                    </div>

                   
                    
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
      </div>
    </Modal>
  )
}

export default LargeModal
