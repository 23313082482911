import formatNumberWithCommas from "components/Common/formatNumberWithCommas"
import handleDownloadInvoice from "components/Common/handleDownloadInvoice"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"

import { Card, Col, CardBody, Row, CardTitle } from "reactstrap"
import { getCountForDashboard, getPreviousInvoicesList } from "store/actions"
import LargeModal from "../viewInvoiceModal"

const Invoices = () => {
  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const [clientId, setClientId] = useState(authUser ? authUser.id : "")
  const dispatch = useDispatch()
  const latestInvoiceState = state =>
    state.Dashboard.previousInvoicesList.billing
  const previousInvoices = useSelector(latestInvoiceState) || []

  console.log("previousInvoices", previousInvoices)
  useEffect(() => {
    dispatch(getPreviousInvoicesList({client_id:authUser.id,user_type:authUser.user_type}))
  }, [dispatch])

  
  const [modalLarge, setModalLarge] = useState(false)

  const toggleModalLarge = () => setModalLarge(!modalLarge)
  const [billingId, setBillingId] = useState()

  const handleBillPreview = (id) => {
    setBillingId(id)
    toggleModalLarge()
  }

  return (
    <React.Fragment>
      <LargeModal
        isOpen={modalLarge}
        toggle={toggleModalLarge}
        billingId={billingId}
      />
      <Row>
        <Row>
          <Col>
            <CardTitle>Invoices</CardTitle>
          </Col>
          <Col className="text-end">
            <Link to="/bills-payments/bill-history">View all</Link>
          </Col>
        </Row>
        {previousInvoices &&
          previousInvoices.map((invoice, index) => (
            <Col lg={4} key={index}>
              <Card className="blog-stats-wid">
                <CardBody>
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <h5 className="mb-0 text-dark mb-2">
                         Rs {    formatNumberWithCommas(invoice.all_total) || 0}
                      </h5>
                      <p className="text-muted mb-2">{invoice.bill_date}</p>
                      <p className="text-muted">{invoice.bill_no}</p>
                    </div>
                    <div className="d-flex avatar-sm ms-auto">
                      <div className="  text-primary font-size-20 me-1">
                        <i
                          className="mdi mdi-eye"
                          style={{cursor:"pointer"}}
                          onClick={()=>{handleBillPreview(invoice.billing_id)}}

                        ></i>
                      </div>
                      <div className="  text-primary font-size-20">
                        <i
                          className="mdi mdi-download"
                          style={{cursor:"pointer"}}
                          onClick={() =>
                            handleDownloadInvoice(invoice.billing_id)
                          }
                        ></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
      </Row>
    </React.Fragment>
  )
}

export default Invoices
