import {
  GET_CLIENT_DETAIL,
  GET_CLIENT_DETAIL_SUCCESS,
  GET_CLIENT_DETAIL_FAIL,
  GET_LASTEST_PROJECT_UNIT,
  GET_LASTEST_PROJECT_UNIT_SUCCESS,
  GET_LASTEST_PROJECT_UNIT_FAIL,
} from "./actionTypes"

export const getClientDetail = clientId => ({
  type: GET_CLIENT_DETAIL,
  clientId,
})

export const getClientDetailSuccess = clientDetail => ({
  type: GET_CLIENT_DETAIL_SUCCESS,
  payload: clientDetail,
})

export const getClientDetailFail = error => ({
  type: GET_CLIENT_DETAIL_FAIL,
  payload: error,
})

export const getLatestProjectUnit = clientId => ({
  type: GET_LASTEST_PROJECT_UNIT,
  clientId,
})

export const getLatestProjectUnitSuccess = latestProjectUnit => ({
  type: GET_LASTEST_PROJECT_UNIT_SUCCESS,
  payload: latestProjectUnit,
})

export const getLatestProjectUnitFail = error => ({
  type: GET_LASTEST_PROJECT_UNIT_FAIL,
  payload: error,
})
