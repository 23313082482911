import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import * as Yup from "yup"
import { Card, Col, Row, CardBody, FormFeedback, Badge } from "reactstrap"
import { useFormik } from "formik"
// import { newCustomerGraph } from "store/actions"
import { useDispatch, useSelector } from "react-redux"
import { getGasConsumption } from "store/actions"

const GasConsumption = () => {
  const dispatch = useDispatch()
  const [apaexlineColumnColors, setApaexlineColumnColors] = useState([])
  const authUser = JSON.parse(localStorage.getItem("authUser"))

  // Selecting newCustomerGraph category from Redux state
  const categoryState = state => state.Dashboard?.gas?.category
  const category = useSelector(categoryState) || []

  const detailState = state => state.Dashboard?.gas?.data
  const detail = useSelector(detailState) || []

  // Mock data for chart
  const data = detail && detail
  const series = [{ name: "Gas Consumption Units", data: data && data }]
  const months = category && category

  // Chart options

  // Initialize form validation
  const validation = useFormik({
    initialValues: {
      filter: "This Year",
    },
    validationSchema: Yup.object({}),
  })

  const options = {
    chart: {
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
        endingShape: "rounded",
      },
    },
    dataLabels: { enabled: false },
    stroke: { show: true, width: 1, colors: ["transparent"] },
    xaxis: {
      categories: months,
      title: {
        text: validation.values.filter,
      },
    },
    yaxis: { title: { text: "In Units" } },
    grid: { borderColor: "#f1f1f1" },
    fill: { opacity: 1 },
    colors: apaexlineColumnColors,
    tooltip: {
      y: {
        formatter: function (value, { seriesIndex, dataPointIndex }) {
          const count = data[dataPointIndex]
          return `${count}`
        },
      },
    },
  }

  // Fetching data colors
  const getDataColors = () => {
    return [ "#FEB019", "#FF4560", "#775DD0"]
  }

  // Effect to set chart colors on component mount
  useEffect(() => {
    const colors = getDataColors()
    setApaexlineColumnColors(colors)
  }, [])

  // Effect to dispatch newCustomerGraph action when filter changes
  useEffect(() => {
    dispatch(getGasConsumption({ filter: validation.values.filter, client_id:authUser.id,user_type:authUser.user_type }))
  }, [validation.values.filter])

  return (
    <React.Fragment>
      <Col lg={6}>
        <Card>
          
          <CardBody>
            <Row>
              <Col className="d-flex align-items-center">
                <h4 className="card-title mb-4">Gas Consumption</h4>
              </Col>
              <Col lg={4}>
                <div className="mb-3">
                  <select
                    name="filter"
                    className={`form-select ${
                      validation.touched.filter && validation.errors.filter
                        ? "is-invalid"
                        : ""
                    }`}
                    onChange={validation.handleChange} // Ensure onChange is linked to handleChange
                    onBlur={validation.handleBlur}
                    value={validation.values.filter} // Ensure value is bound to Formik's values.filter
                  >
                    <option value="This Week">This Week</option>
                    <option value="This Month">This Month</option>
                    <option value="This Year">This Year</option>
                    <option value="Previous Week">Previous Week</option>
                    <option value="Previous Month">Previous Month</option>
                    <option value="Previous Year">Previous Year</option>
                  </select>
                  {validation.touched.filter && validation.errors.filter ? (
                    <FormFeedback type="invalid">
                      {validation.errors.filter}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <ReactApexChart
              options={options}
              series={series && series}
              type="bar"
              height={350}
            />
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default GasConsumption
